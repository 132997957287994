let ua = navigator.userAgent.toLowerCase();
let isSafari = false;
if (ua.indexOf("safari") !== -1) {
  if (ua.indexOf("chrome") > -1) {
    isSafari = false; // Chrome
  } else {
    isSafari = true; // Safari
  }
}
/* eslint-disable no-useless-escape */
export const EMAIL_PATTERN = {
  regex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: "app.validation.email",
};

export const NUMBER_TEXT_PATTERN = {
  regex: "^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$",
  message: "app.validation.text",
};

export const TEXT_PATTERN = {
  regex: "^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$",
  message: "app.validation.text",
};

export const ONLY_TEXT_PATTERN = {
  regex: "^[A-Za-z _]*[A-Za-z][A-Za-z _]*$",
  message: "app.only.validation.text",
};

export const NUMBER_RANGE_ONE_HUNDRED_PATTERN = {
  regex: "^[1-9][0-9]?$|^100$",
  message: "app.validation.numbet.one.hundred",
};

export const NO_SPECIAL_CHAR_PATTERN = {
  regex: "^[a-zA-Z0-9_]*$",
  message: "app.validation.special.char",
};

export const ONLY_NINE_CHAR_PATTERN = {
  regex: "^[0-9]{0,9}$",
  message: "app.validation.nine.char",
};

export const USERID_LENGTH_PATTERN = {
  regex: "^.{6,32}$",
  message: "app.validation.userid",
};

export const MAX_50_LENGTH_PATTERN = {
  regex: "^.{0,50}$",
  message: "app.validation.max50",
};
export const MAX_10_LENGTH_PATTERN = {
  regex: "^.{0,10}$",
  message: "app.validation.max10",
};
export const MAX_30_LENGTH_PATTERN = {
  regex: "^.{0,30}$",
  message: "app.validation.max30",
};
export const PIN_CODE_PATTERN = {
  regex: /^\d{3}\-{0,1}\d{4}$/,
  message: "app.validation.pincode",
};

export const PHONE_PATTERN = {
  regex:
    /^((0(\d{1}-?\d{4}|\d{2}-?\d{3}|\d{3}-?\d{2}|\d{4}-?\d{1}|[5789]0-?\d{4})-?\d{4})|(0120-?\d{3}-?\d{3}))$/,
  message: "app.validation.phone",
};

export const FAX_PATTERN = {
  regex:
    /^((0(\d{1}-?\d{4}|\d{2}-?\d{3}|\d{3}-?\d{2}|\d{4}-?\d{1}|[5789]0-?\d{4})-?\d{4})|(0120-?\d{3}-?\d{3}))$/,
  message: "app.validation.fax",
};

export const PHONE_PATTERN_UPDATED = {
  regex: /^(\d{2}-{0,1}\d{3,4}-{0,1}\d{4})$/,
  message: "app.validation.phone",
};

export const USERID_HALFWIDTH_PATTERN = {
  regex: "^[a-zA-Z0-9\\-\\.\\_]*$",
  message: "app.validation.halfwidth",
};
export const POSITIVE_NUMBER_PATTERN = {
  regex: "^[1-9][0-9]*$",
  message: "app.validation.positivenumber",
};
export const NUMBER_PATTERN = {
  regex: "^[0-9]*$",
  message: "app.validation.quantityanddaily",
};
export const POSITIVE_NUMBER_CHECK = {
  regex: "[^0]+",
  message: "app.validation.positivenumber",
};
export const CHECK_BLANK = {
  regex: "[^\\sS]+",
  message: "app.validation.empty",
};

export const CHECK_VALID_STRINGS = isSafari
  ? {
      regex: "[^\\sS]+",
      message: "app.validation.empty",
    }
  : {
      regex: "^(?! )[一-龠 ]+|[ぁ-ゔ ]+|[ァ-ヴー ]+|[A-Za-z0-9 ]+|[々〆〤 ]*(?<! )$",
      message: "app.validation.empty",
    };

export const PASSWORD_LENGTH_PATTERN = {
  regex: "^.{8,}$",
  message: "app.validation.passwordLength",
};
export const OTP_LENGTH_PATTERN = {
  regex: "^[0-9]{6}$",
  message: "app.validation.OTPLength",
};
export const NO_SPECIAL_CHAR = {
  regex: '`|~|!|@|#|$|%|^|&|*|(|)|+|=|[|{|]|}|||\\|\'|<|,|.|>|?|/|""|;|:|s',
  message: "app.validation.OTPLength",
};

export const NO_SPECIAL_CHAT_PATTERN = {
  // regex: /^[^*|\":<>[\]{}`\\()';@&$]+$/,
  regex:
    "^[a-zA-Z0-9_\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf- ]*$",
  message: "app.validation.nospecialchar",
};

/** 
   * The ranges are (https://stackoverflow.com/questions/15033196/using-javascript-to-check-whether-a-string-contains-japanese-characters-includi/15034560):
      3000 - 303f: Japanese-style punctuation
      3040 - 309f: Hiragana
      30a0 - 30ff: Katakana
      ff00 - ff9f: Full-width Roman characters and half-width Katakana
      4e00 - 9faf: CJK unified ideographs - Common and uncommon Kanji
      3400 - 4dbf: CJK unified ideographs Extension A - Rare Kanji
*/
export const ONLY_JP_PATTERN = {
  regex:
    "^[a-zA-Z \u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]*$",
  message: "app.validation.only.jp",
};

export const MAX_100_LENGTH_PATTERN = {
  regex: "^.{0,100}$",
  message: "app.validation.max100",
};
export const ONLY_NUMBER_PATTERN = {
  regex: "^[0-9]*$",
  message: "only numbers allowed",
};
export const MAX_1000_LENGTH_PATTERN = {
  regext: "^.{0,1000}$",
  message: "app.validation.max1000",
};

export const MAX_20_LENGTH_PATTERN = {
  regex: "^.{0,20}$",
  message: "app.validation.max20",
};

export const CARD_NUMBER_PATTERN = {
  regex: "^[0-9 -]+$",
  message: "app.validation.card.pattern",
};

export const SIGNUP_CODE_LENGTH_PATTERN = {
  regex: "^.{5,}$",
  message: "app.validation.signupcode",
};

export const ONLY_ENGLISH_NUMBER_SPECIAL_CHARACTER_PATTERN = {
  regex: "^([a-zA-Z0-9!@#$%^*_|:/.])*$",
  message: "app.validation.password.english",
};
export const EMPTY_FIELD = "This Field can't be empty";
export const SHOULD_BE_ALPHANUMERIC_CHARACTERS = {
  regex: /^.*(?=.{8,})(?=.*\d)((?=.*[a-z])).*$/,
  message: "app.validation.alphanumeric.characters",
};

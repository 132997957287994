import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { GreyBorderInput, OrangeButton } from "../PhaseBStyles/style";
import { FiX } from "react-icons/fi";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { CHECK_BLANK, MAX_100_LENGTH_PATTERN } from "../../validation/validationPattern";

export function InChargeEditDialog(props) {
  const { handleClose, modalOpen, inChargeEdit, registerIncharge } = props;
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    inCharge: Yup.string()
      .required(t("app.fields.required"))
      .matches(MAX_100_LENGTH_PATTERN.regex, t(MAX_100_LENGTH_PATTERN.message))
      .matches(CHECK_BLANK.regex, t(CHECK_BLANK.message)),
    position:
      inChargeEdit != null && inChargeEdit.position != null
        ? Yup.string().matches(MAX_100_LENGTH_PATTERN.regex, t(MAX_100_LENGTH_PATTERN.message))
        : "",
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    shouldValidate: true,
  };

  const { register, handleSubmit, formState, setValue, reset } = useForm(formOptions);
  const { errors } = formState;

  const resetAndCloseDialog = () => {
    handleClose();
    reset();
  };

  const upDateInChargeValue = (e) => {
    let charge = inChargeEdit;
    charge.name = e.target.value;
    setInChargeEdit({ ...charge });
    setValue("inCharge", e.target.value);
  };

  const updatePostionValue = (e) => {
    let charge = inChargeEdit;
    charge.position = e.target.value;
    setInChargeEdit({ ...charge });
    setValue("position", e.target.value);
  };

  const onSubmit = (data) => {
    registerIncharge({ name: data.inCharge, position: data.position }); //追加
    resetAndCloseDialog();
  };

  return (
    <Dialog
      onClose={resetAndCloseDialog}
      open={modalOpen}
      maxWidth={"sm"}
      fullWidth={true}
      scroll="body"
    >
      <DialogTitle className="">
        <FiX className="cursor-pointer-custom font-size-24" onClick={resetAndCloseDialog} />
        <span className="font-size-16 font-weight-700 opacity-80 ms-5 d-md-none">
          {"担当者追加"}
        </span>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className="px-md-5 px-3">
          <Grid container mt={2} columns={12} className="pt-0 d-md-block d-none">
            <Grid item xs={12}>
              <div className="font-size-16 font-weight-700 opacity-80">{"担当者追加"}</div>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={5}>
            <Stack direction="row" alignItems="center">
              <InputLabel
                htmlFor="bootstrap-input"
                className="font-size-14 opacity-80 font-weight-400"
              >
                担当者名
              </InputLabel>
              <InputLabel
                className="font-size-11 opacity-80 label-required"
                htmlFor="bootstrap-input"
              >
                必須
              </InputLabel>
            </Stack>
            <GreyBorderInput
              type="text"
              placeholder="パーツ太郎"
              onChange={(e) => {
                upDateInChargeValue(e);
              }}
              style={{
                width: "100%",
                fontSize: "14px",
                height: "44px",
              }}
              {...register("inCharge")}
              data-cy="incharge"
            />
            {errors && errors.inCharge ? (
              <Typography
                className="font-size-14 opacity-60 error-message mt-1"
                htmlFor="bootstrap-input"
              >
                {errors.inCharge?.message}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} className="mt-3">
            <InputLabel htmlFor="bootstrap-input" className="font-size-14 opacity-80">
              {"役職"}
            </InputLabel>
            <GreyBorderInput
              className="font-size-14 mt-1"
              type="text"
              placeholder="役職"
              onChange={(e) => {
                updatePostionValue(e);
              }}
              {...register("position")}
              style={{
                width: "100%",
                fontSize: "14px",
                height: "44px",
              }}
              data-cy="position"
            />
            {errors && errors.position ? (
              <Typography className="font-size-14 opacity-60" htmlFor="bootstrap-input" mt={1}>
                {errors.position?.message}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
        <center className="mt-5">
          <OrangeButton
            className="mt-5 mb-5  font-size-16 font-weight-700"
            variant="contained"
            style={{ width: "50%" }}
            disabled={!formState.isValid}
            onClick={handleSubmit(onSubmit)}
            data-cy="addInchargeSubmitButton"
          >
            {"追加する"}
          </OrangeButton>
        </center>
      </form>
    </Dialog>
  );
}

import React, { useState, useEffect } from "react";
import { Grid, Button, Stack, Typography, Chip, AppBar, Toolbar, IconButton } from "@mui/material";
import "./cart.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import { FiX } from "react-icons/fi";
import { NoEncryption } from "@mui/icons-material";

export default function CartPolicyDialog(props) {
  const policyTerms = [
    {
      id: 1,
      title: "返品について",
      description: `ご注文いただいた商品は、お届け後1週間以内に箱を開封していない状態でしたらご返品の対応が可能です。（一部受付できない商品もございます。）
        その場合、お届け時の送料、点検手数料、ご返却時の送料をご負担いただきます。（まだ商品をお届けしていない場合でも、発送手続き完了後は上記のキャンセル料が発生します。）お届けした商品が異なる、破損しているなど、不具合がございましたら商品到着後1週間以内にご連絡下さい。`,
    },
    {
      id: 2,
      title: "返金について",
      description:
        "商品を弊社規定に従って返送いただき、商品がご指定させていただいた住所に到着し、中身を確認させていただき次第、問題がないことを確認できましたら所定の方法にて、返金をさせていただきます。",
    },
  ];

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-popup"
        scroll={"body"}
        data-cy="policyTermsDialog"
      >
        <ClearIcon
          className="p-2 cursor opacity-80"
          onClick={props.handleClose}
          sx={{ fontSize: 45 }}
          data-cy={"closePolicyTerms"}
        />
        <DialogContent>
          <DialogContentText>
            {policyTerms &&
              policyTerms.map((item) => {
                return (
                  <div key={item.id} className="mb-5">
                    <Typography variant="p" component="p" className="font-size-16 font-weight-700">
                      {item.title}
                    </Typography>
                    <Typography variant="p" component="p" className="font-size-14 mt-2">
                      {item.description}{" "}
                    </Typography>
                  </div>
                );
              })}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

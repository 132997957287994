import {
  GET_INFO_DZONE_PARTS_INFO_B5_REDUCER,
  GET_INFO_DZONE_PARTS_NO_B5_REDUCER,
  GET_INFO_DZONE_PARTS_COLOR_B5_REDUCER,
  GET_INFO_DZONE_PARTS_LIST_INFO_B6_REDUCER,
  GET_PARTS_LIST_INFO,
  GET_INFO_DZONE_PARTS_NO_B5_REDUCER_RESET,
  SET_FAV_REDUCER,
  DEL_FAV_REDUCER,
  TYPE_OF_SEARCH,
  BODY_NUMBER_SEARCH,
  EMPTY_BODY_NUMBER_SEARCH,
  GET_INFO_DZONE_PARTS_LIST_INFO_B6_RESET_REDUCER,
} from "./constants";

let initialState = {
  partsList: [],
  partsNo: null,
  colorCode: [],
  searchPartsList: "",
  partsFromSelectToList: [],
  flg: "",
  typeOfSearchNav: false,
  bodyNumberSearch: null,
};
const partsReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case GET_INFO_DZONE_PARTS_INFO_B5_REDUCER:
      actionState.partsList = action.payload;
      return actionState;
    case GET_INFO_DZONE_PARTS_NO_B5_REDUCER:
      actionState.partsNo = {
        partsNo: action.payload,
        notifier: Math.random(),
      };
      return actionState;
    case GET_INFO_DZONE_PARTS_NO_B5_REDUCER_RESET:
      actionState.partsNo = null;
      return actionState;
    case GET_INFO_DZONE_PARTS_COLOR_B5_REDUCER:
      actionState.colorCode = action.payload;
      return actionState;
    case GET_INFO_DZONE_PARTS_LIST_INFO_B6_REDUCER:
      actionState.searchPartsList = Object.assign({}, state, action.payload);
      return actionState;
    case GET_INFO_DZONE_PARTS_LIST_INFO_B6_RESET_REDUCER:
      actionState.searchPartsList = null;
      return actionState;
    case GET_PARTS_LIST_INFO:
      actionState.partsFromSelectToList = action.payload;
      return actionState;
    case SET_FAV_REDUCER:
      actionState.flg = action.payload;
      return actionState;
    case DEL_FAV_REDUCER:
      actionState.flg = "";
      return actionState;
    case TYPE_OF_SEARCH:
      actionState.typeOfSearchNav = action.payload;
      return actionState;
    case BODY_NUMBER_SEARCH:
      return {
        ...state,
        bodyNumberSearch: action.payload,
      };
    case EMPTY_BODY_NUMBER_SEARCH:
      return {
        ...state,
        bodyNumberSearch: null,
      };
    default:
      return actionState;
  }
};

export { partsReducer };

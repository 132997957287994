import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function PageTitle(props) {
  const theme = useTheme();
  const title = props.title;
  const appClasses = props.appClasses;
  const mdMarginTop = props.mdMarginTop ?? "24px";
  const xsMarginTop = props.xsMarginTop ?? "16px";

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const mt = isMobile ? xsMarginTop : mdMarginTop;

  return (
    <Grid item xs={12} sx={{ marginTop: mt }}>
      <Typography
        data-cy="pageTitle"
        component="h1"
        variant="h1"
        className="font-size-24 opacity-80 font-weight-400"
      >
        {title}
      </Typography>
      <hr className="opacity-20" style={{ marginTop: "1rem" }} />
    </Grid>
  );
}

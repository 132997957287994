import {
  CREATE_CORPORATE,
  GET_INDUSTRY,
  GET_PREFECTURE,
  FIND_PREFECTURE,
  CLEAR_PREFECTURES,
} from "./constants";

let initialState = {
  corporate: null,
  industoryList: [],
  prefectureList: [],
  prefecture: {},
};
const registerReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case CREATE_CORPORATE:
      actionState.corporate = action.payload;
      return actionState;
    case GET_INDUSTRY:
      let actionIndustry = state;
      actionIndustry.industoryList = action.payload;
      return actionIndustry;
    case GET_PREFECTURE:
      let actionPrefecture = state;
      actionPrefecture.prefectureList = action.payload;
      return actionPrefecture;
    case FIND_PREFECTURE:
      let prefecture = state;
      prefecture.prefecture = action.payload;
      return prefecture;
    case CLEAR_PREFECTURES:
      actionState.prefectureList = [];
      actionState.prefecture = {};
      return actionState;
    default:
      return state;
  }
};

export { registerReducer };

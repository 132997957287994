import React from "react";
import { Grid, Link } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import BreadcrumbRoute from "../types/breadcrumbTypes";

type BreadcumbProps = {
  routes: BreadcrumbRoute[];
};

const Breadcumb = ({ routes }: BreadcumbProps) => {
  const history = useHistory();
  const location = useLocation();

  const handleLinkClick = (link: string, state: object | undefined): void => {
    history.push(link, state);
  };

  return (
    <Grid item md={12} sx={{ display: { xs: "none", md: "block" }, mt: 2 }}>
      {Array.isArray(routes) &&
        routes.map((eachRoute, index) => (
          <div key={index} className="d-inline-block">
            {eachRoute.link ? (
              <Link
                onClick={() => handleLinkClick(eachRoute.link!, eachRoute.state)}
                underline="none"
                sx={{ color: location.pathname === eachRoute.link ? "#ffffff" : "#bdbdbd" }}
                className="cursor-pointer link-hover"
              >
                <span className="font-size-14 opacity-80 pr-2">{eachRoute.name}</span>
              </Link>
            ) : (
              <span className="font-size-14 opacity-80">{eachRoute.name}</span>
            )}

            <span className="font-size-14 opacity-80 pr-2">
              {routes && index !== routes.length - 1 ? " > " : ""}
            </span>
          </div>
        ))}
    </Grid>
  );
};

export default Breadcumb;

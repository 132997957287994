import { put, takeLatest, call } from "redux-saga/effects";
import { GET_CAUTION_PLATE, PUT_CAUTION_PLATE } from "./cautionPlateConstants";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { FETCH_CAUTION_PLATE_API } from "../../../utils/apiConstants";
import instance from "../../../utils/AxiosIntance";

function requestAPICall(API, payload) {
  return axios
    .create({
      // headers: {
      //   "X-MICROCMS-API-KEY":"74b2ecc1c7da4ce6b1a093fe8475128881b3"
      // }
    })
    .post(FETCH_CAUTION_PLATE_API, {
      params: payload,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  // return instance.get(API, { params: payload }).then((response) => {
  //   return _.get(response, "data", {});
  // })
  //   .catch((error) => {
  //     return {
  //       message: _.get(error, "response.data.message", {}),
  //       success: false,
  //     };
  //   });
}

const getCautionPlate = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;

  isLoading(true);

  try {
    response = yield call(requestAPICall, FETCH_CAUTION_PLATE_API, payload.payload.data);

    if (response) {
      yield put({
        type: GET_CAUTION_PLATE,
        payload: payload.payload.customData,
        error: false,
      });
    } else {
      yield put({
        type: GET_CAUTION_PLATE,
        payload: payload.payload.customData,
        error: true,
      });
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("catch --->");
  }

  isLoading(false);
};

function* cautionPlateSaga() {
  yield takeLatest(GET_CAUTION_PLATE, getCautionPlate);
}

export default cautionPlateSaga;

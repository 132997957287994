import {
  CLEAR_PREFECTURE,
  LOGIN_DZONE_MY_PAGE_NOT_LOGIN_REDUCER,
  PUT_PREFECTURE,
} from "./constants";

let initialState = {
  access_token: null,
  type: null,
  prefecture: null,
};
const myPageNoLoginReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case LOGIN_DZONE_MY_PAGE_NOT_LOGIN_REDUCER:
      actionState.access_token = action.payload.access_token;
      actionState.type = action.payload.type;
      return { ...actionState };
    case PUT_PREFECTURE:
      return {
        ...state,
        prefecture: action.payload,
      };
    case CLEAR_PREFECTURE:
      actionState.prefecture = null;
      return actionState;
    default:
      return actionState;
  }
};

export { myPageNoLoginReducer };

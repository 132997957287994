import {
  GET_MASTER_PREFECTURE_INFO_REDUCER,
  GET_MASTER_PREFECTURE_INFO_REDUCER_RESET,
  ROUTE_USER_SHIPPING_DATA_DZONE_A21_A23,
  ROUTE_USER_SHIPPING_DATA_DZONE_A21_A23_RESET,
  PREFECTURE_INFO_DATA_DZONE_REDUCER,
} from "./constants";

let initialState = {
  prefectureObj: null,
  editMyPageShipping: null,
  prefecture: null,
};

const shippingReducer = (state = initialState, action) => {
  let actionState = initialState;
  switch (action.type) {
    case GET_MASTER_PREFECTURE_INFO_REDUCER:
      actionState.prefectureObj = action.payload;
      return actionState.prefectureObj;
    case GET_MASTER_PREFECTURE_INFO_REDUCER_RESET:
      actionState.prefectureObj = null;
      return actionState;
    case ROUTE_USER_SHIPPING_DATA_DZONE_A21_A23:
      actionState.editMyPageShipping = action.payload;
      return actionState;
    case ROUTE_USER_SHIPPING_DATA_DZONE_A21_A23_RESET:
      actionState.editMyPageShipping = null;
      return actionState;
    case PREFECTURE_INFO_DATA_DZONE_REDUCER:
      actionState.prefecture = action.payload;
      return actionState;
    default:
      return actionState;
  }
};

export { shippingReducer };

import React, { useState, useEffect } from "react";
import logoUZonedarkUzone from "../assets/images/logoUZonedarkUzone.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
IconButton;
import { IconButton, Box, rgbToHex } from "@mui/material";
import { ConstructionOutlined } from "@mui/icons-material";

export default function OrderImage(props) {
  const {
    imageWithId,
    productId,
    enableProductClick,
    history,
    imgPath,
    imageId,
    setImageId,
    scroll,
  } = props;

  const moveToProductDetails = (e) => {
    if (enableProductClick !== false) {
      history.push("/product/" + productId);
      e.stopPropagation();
    }
  };

  //配列にある画像のidとimageIdが一致する要素を返す（<img/>の画像選定）
  const findImage = () => {
    const imageFileter = imageWithId?.find((image) => image.id === imageId && image.src);
    return imageFileter.src;
  };

  const handleLeftScroll = () => {
    setImageId(imageId - 1);
  };
  const handleRightScroll = () => {
    setImageId(imageId + 1);
  };

  return (
    <React.Fragment>
      <Box
        style={{
          position: "relative",
          borderRadius: "8px",
        }}
      >
        <img
          data-cy="expandedImage"
          alt="product"
          src={scroll === true ? (imageWithId?.length > 0 ? findImage() : "") : imgPath}
          style={{
            height: "100%",
            width: "100%",
            pointerEvents: "none",
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = logoUZonedarkUzone;
            currentTarget.style.padding = "22.5%";
            currentTarget.style.background = "rgba(31, 31, 31, 1)";
            currentTarget.style.width = "100%";
            currentTarget.style.height = "auto";
          }}
          onClick={(e) => {
            moveToProductDetails(e);
          }}
          className={`${props.responsive} d-block cursor`}
        />
        {scroll === true && (
          <>
            <IconButton
              data-cy="leftIcon"
              component="a"
              disabled={imageId < 1}
              onClick={handleLeftScroll}
              sx={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "5%",
                color: "white",
                backgroundColor: " rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  color: "rgba(255, 255, 255, 0.5)",
                },
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>

            <IconButton
              data-cy="rightIcon"
              component="a"
              disabled={imageId >= imageWithId?.length - 1}
              onClick={handleRightScroll}
              sx={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                right: "5%",
                color: "white",
                backgroundColor: " rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  color: "rgba(255, 255, 255, 0.5)",
                },
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </>
        )}
      </Box>
    </React.Fragment>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Stack, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import "./addCardDetails.css";
import "../../views/PhaseCStyles/style.css";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { putCardAsDefault } from "../../views/Card/action";

// VIEWS
import { LoadingView, ProcessView } from "./AddCardDetailsViews";

// ACTION
import { addCardDetailsStatus } from "../../views/Card/action";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { CardIdContext } from "../../contexts/CardIdProvider";

export const AddCardDetailsUpdated = (props) => {
  // Declare a new state variable, which we'll call "count"
  let history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Get Redux State Values
  const cardInfo = useSelector((state) => {
    return state.cardReducer;
  });

  const [isProccessCompleted, setIsProcessCompleted] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { setCardId } = useContext(CardIdContext);

  // Props
  const { refresh, open, setOpen, clientSecret, setIsAddCardClicked } = props;

  useEffect(() => {
    setOpen(open);
    dispatch(
      addCardDetailsStatus({
        completed: null,
      })
    );
  }, [open]);

  const handleClose = () => {
    // Make loading false
    setIsLoading(false);
    // Make process complete false on redux
    dispatch(
      addCardDetailsStatus({
        completed: null,
      })
    );
    // Close modal
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleReturn = () => {
    handleClose();
    refresh();
  };

  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    await elements.submit();

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      // card: elements.getElement(PaymentElement),
      clientSecret: clientSecret,
      redirect: "if_required",

      confirmParams: {
        payment_method_data: {
          billing_details: {
            address: {
              country: "JP", // ユーザーの国コード
            },
          },
        },
      },
    });

    if (result.error) {
      setIsLoading(false);
      // Show error to your customer (for example, payment details incomplete)
      toastr.error("エラーが発生しました。");
    } else {
      setIsLoading(false);
      toastr.success("カードを追加しました。");
      setOpen(false);
      setIsAddCardClicked(false);

      const filteredData = {
        stripe_card_id: result.setupIntent.payment_method,
      };

      await dispatch(
        putCardAsDefault({
          filteredData,
          history: props.history,
          isLoading: props.isLoading,
        })
      );

      refresh();
      setCardId(() => result.setupIntent.payment_method);
    }
  };

  // UIの設定
  const paymentElementOptions = {
    layout: "tabs",
    showIcon: true, // アイコンの表示
    fields: {
      billingDetails: {
        address: {
          country: "never",
        },
      },
    },
  };

  return (
    <>
      <Grid className="row add-card-details">
        <div className="add-card-details">
          <Dialog
            data-cy="addCardDetails"
            open={open}
            onClose={handleCloseDialog}
            scroll={"body"}
            className="add-card-details"
          >
            <div className="Dialog_Box">
              <div>
                <ClearIcon
                  className="p-2 cursor"
                  onClick={handleClose}
                  sx={{ fontSize: 40 }}
                  data-cy="clearIcon"
                />
              </div>

              <DialogTitle>
                <Typography variant="h6" component="span">
                  クレジットカード追加
                </Typography>
              </DialogTitle>
              <form id="payment-form" onSubmit={onSubmit}>
                <PaymentElement id="payment-element" options={paymentElementOptions} />
                <DialogActions>
                  <Grid item md={12} sm={12} xs={12} sx={{ mt: 0, mb: 1 }}>
                    <Stack
                      spacing={2}
                      lg={12}
                      xs={12}
                      sm={9}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ m: 0 }}
                      className={!isLoading || stripe || elements ? "input-valid-form" : ""}
                    >
                      <Grid item md={6} xs={10} sm={10}>
                        {isLoading || !stripe || !elements ? (
                          <LoadingView
                            isLoading={isLoading}
                            disabled={isLoading || !stripe || !elements}
                            submit={onSubmit}
                            close={handleClose}
                          />
                        ) : !isLoading && isProccessCompleted ? (
                          <ProcessView
                            isProccessCompleted={isProccessCompleted}
                            refresh={handleReturn}
                            submit={onSubmit}
                          />
                        ) : !isLoading && !isProccessCompleted ? (
                          <ProcessView
                            isProccessCompleted={isProccessCompleted}
                            refresh={handleReturn}
                            submit={onSubmit}
                          />
                        ) : (
                          <LoadingView
                            isLoading={isLoading}
                            disabled={isLoading || !stripe || !elements}
                            submit={onSubmit}
                            close={handleClose}
                          />
                        )}
                      </Grid>
                    </Stack>
                  </Grid>
                </DialogActions>
              </form>
            </div>
          </Dialog>
        </div>
      </Grid>
    </>
  );
};

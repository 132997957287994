import { put, takeLatest, call } from "redux-saga/effects";
import {
  LOGIN_DZONE_MY_PAGE_NOT_LOGIN_SAGA,
  LOGIN_DZONE_MY_PAGE_NOT_LOGIN_REDUCER,
  UPDATE_USER_INFO,
  GET_PREFECTURE,
  PUT_PREFECTURE,
} from "./constants";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import {
  COMPANT_FIND_ZIP_CODE_API,
  SIGN_IN_MY_PAGE_API,
  UPDATE_MYPROFILE_API,
  USER_MYPAGE_INFO_A12_API,
} from "../../../utils/apiConstants";
import instance from "../../../utils/AxiosIntance";
import { GET_INFO_DZONE_MY_PAGE_SAGA } from "../../Admin/MyPage/MyPage/constants";
import { API_FAIL } from "../../Api/constants";
import { setCookie } from "../../../utils/cookieUtils";

function userLoginHandler(payload, isLoading) {
  isLoading(true);
  return instance
    .post(SIGN_IN_MY_PAGE_API, payload)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

function userMyPageInfoHandler() {
  return instance
    .get(USER_MYPAGE_INFO_A12_API)
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
        status: _.get(error, "response.status", 0),
      };
    });
}

function updateMyProfileAPICall(payload, isLoading) {
  isLoading(true);
  return instance
    .post(UPDATE_MYPROFILE_API, payload)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

function getPrefectureObjectAPICall(payload, isLoading) {
  isLoading(true);
  return instance
    .get(COMPANT_FIND_ZIP_CODE_API, { params: payload })
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

/* API calls END  */
const loginHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  const history = payload.payload.history;
  const redirectTo = payload.payload.redirectTo;
  let response;
  try {
    response = yield call(userLoginHandler, payload.payload.payload, isLoading);
    if (_.get(response, "result", false)) {
      setCookie("access_token", _.get(response, "data.access_token", {}));

      yield put({
        type: LOGIN_DZONE_MY_PAGE_NOT_LOGIN_REDUCER,
        payload: {
          access_token: _.get(response, "data.access_token"),
          type: _.get(response, "data.type"),
        },
      });

      //GTMにuser_idをを渡すための処理
      const accessResponse = yield call(userMyPageInfoHandler, payload.payload.params);

      // Login GTM
      window.dataLayer.push({
        event: "login",
        event_scope: {
          user_type: response.data.type ?? "", //1, 2, 3 actual values
          user_id: accessResponse?.data?.user?.user_id ?? "",
        },
      });

      if (redirectTo === "/cart" || redirectTo === "/checkout") return; // cart, checkoutの場合は、別途カート追加処理を行う

      const referrer = localStorage.getItem("referrer") ?? "";
      if (referrer) {
        localStorage.removeItem("referrer");
        history.push(referrer);
        return;
      }

      history.push(redirectTo);
      return;
    } else {
      yield put({
        type: API_FAIL,
        payload: {
          message: _.get(response, "message"),
        },
      });
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  }
};

const updateMyProfile = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let history = payload.payload.history;
  try {
    yield call(updateMyProfileAPICall, payload.payload.payload, isLoading);
    yield put({
      type: GET_INFO_DZONE_MY_PAGE_SAGA,
      payload: {
        params: null,
        isLoading: isLoading,
        history: history,
      },
    });

    // Navigating to A-12 Screen
    history.push("/mypage/person");
  } catch (error) {
    console.error("catch --->");
  }
};

const getPrefectureObject = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  let history = payload.payload.history;
  try {
    response = yield call(getPrefectureObjectAPICall, payload.payload.pincode, isLoading);
    if (_.get(response, "result", false)) {
      yield put({ type: PUT_PREFECTURE, payload: _.get(response, "data") });
    } else {
      yield put({ type: PUT_PREFECTURE, payload: null });
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("catch --->");
  }
};

function* myPageNoLoginSaga() {
  yield takeLatest(LOGIN_DZONE_MY_PAGE_NOT_LOGIN_SAGA, loginHandler);
  yield takeLatest(UPDATE_USER_INFO, updateMyProfile);
  yield takeLatest(GET_PREFECTURE, getPrefectureObject);
}

export default myPageNoLoginSaga;

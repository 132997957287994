import {
  ADD_FAVOURITE_PRODUCT_SAGA,
  DELETE_FAVOURITE_PRODUCT_SAGA,
  PUT_FAVOURITE_PRODUCT_LIST_SAGA,
} from "./constants";

let initialState = {
  favouriteProductList: [],
  flag: -1,
  favouriteItem: null,
};

const favouriteProductReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case PUT_FAVOURITE_PRODUCT_LIST_SAGA:
      actionState.favouriteProductList = action.payload;
      return { ...actionState };
    case DELETE_FAVOURITE_PRODUCT_SAGA:
      actionState.flag = action.payload.flag;
      actionState.favouriteItem = action.payload.favouriteItem;
      return { ...actionState };
    case ADD_FAVOURITE_PRODUCT_SAGA:
      actionState.flag = action.payload.flag;
      actionState.favouriteItem = action.payload.favouriteItem;
      return { ...actionState };
    default:
      return state;
  }
};

export { favouriteProductReducer };

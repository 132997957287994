import { API_FAIL } from "./constants";

let initialState = {
  message: null,
};

const reducerForApiFail = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case API_FAIL:
      return {
        ...state,
        message: action.payload.message,
      };
    default:
      return actionState;
  }
};

export { reducerForApiFail };

import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function CustomLoader(props) {
  const theme = useTheme();
  const title = props.title;
  const appClasses = props.appClasses;
  const mdMarginTop = props.mdMarginTop ?? "24px";
  const xsMarginTop = props.xsMarginTop ?? "16px";

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const mt = isMobile ? xsMarginTop : mdMarginTop;

  return (
    <div id="cover-spin">
      <img
        src="/loading-loading-forever.gif"
        width="44px"
        height="44px"
        alt="loadign image"
        style={{ background: "transparent", width: "44px", height: "44px", userSelect: "none" }}
      />
    </div>
  );
}

import _ from "lodash";
import { GET_NOTICE_DETAIL_SAGA } from "./constants";
let initialState = {
  noticeDetail: [],
};

const noticeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTICE_DETAIL_SAGA:
      return { orderParts: action.payload.payload.data };
    default:
      return state;
  }
};
export { noticeReducer };

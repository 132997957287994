import React, { useState, createContext, useMemo } from "react";


export const CardIdContext = createContext();

function CardIdProvider({children}) {
  const [cardId, setCardId] = useState();
  const defaultCardIdContext = useMemo(
    () => ({
      cardId,
      setCardId,
    }),
    [cardId, setCardId]
  )
  return (
    <CardIdContext.Provider value={defaultCardIdContext}>
      {children}
    </CardIdContext.Provider>
  )
}

export default CardIdProvider

export const GET_MASTER_PREFECTURE_INFO =
  "view/Shipping/Shipping/constants/GET_MASTER_PREFECTURE_INFO";
export const GET_MASTER_PREFECTURE_INFO_REDUCER =
  "view/Shipping/Shipping/constants/GET_MASTER_PREFECTURE_INFO_REDUCER";
export const GET_MASTER_PREFECTURE_INFO_REDUCER_RESET =
  "view/Shipping/Shipping/constants/GET_MASTER_PREFECTURE_INFO_REDUCER_RESET";
export const POST_USER_SHIPPING_DATA_DZONE_A20 =
  "view/Shipping/Shipping/constants/POST_USER_SHIPPING_DATA_DZONE_A20";
export const PUT_USER_SHIPPING_DATA_DZONE_A21 =
  "view/Shipping/Shipping/constants/PUT_USER_SHIPPING_DATA_DZONE_A21";
export const ROUTE_USER_SHIPPING_DATA_DZONE_A21_A23 =
  "view/Shipping/Shipping/constants/ROUTE_USER_SHIPPING_DATA_DZONE_A21_A23";
export const ROUTE_USER_SHIPPING_DATA_DZONE_A21_A23_RESET =
  "view/Shipping/Shipping/constants/ROUTE_USER_SHIPPING_DATA_DZONE_A21_A23_RESET";
export const PREFECTURE_INFO_DATA_DZONE =
  "view/Shipping/Shipping/constants/PREFECTURE_INFO_DATA_DZONE";
export const PREFECTURE_INFO_DATA_DZONE_REDUCER =
  "view/Shipping/Shipping/constants/PREFECTURE_INFO_DATA_DZONE_REDUCER";
export const GET_INFO_DZONE_SHIPPING_LIST_SAGA =
  "view/MyPage/MyPage/GET_INFO_DZONE_SHIPPING_LIST_SAGA";
export const GET_INFO_DZONE_SHIPPING_LIST_REDUCER_RESET =
  "view/MyPage/MyPage/GET_INFO_DZONE_SHIPPING_LIST_REDUCER_RESET";

import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import initialState from "./appInitState";
import rootReducer from "./rootReducer";

export type ReduxType = {
  cardReducer: {
    card: [],
    isDefault: boolean,
    isProccessCompleted: boolean
  },
  myPageInfoReducer: {
    inChargeList: [],
    myPageInfo: { user: { stripe_customer_id: number } },
    myPageSpTop: boolean,
    shippingList: [],
    type: number,
  }
}

interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
declare var window: ExtendedWindow;

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (err) {
    console.log(err);
    return initialState;
  }
}

const persistedState = loadFromLocalStorage();
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export default store;

import instance from "../../utils/AxiosIntance";
import { GET_NOTICE_DETAIL_SAGA, GET_NOTICE_DETAIL } from "./constants";
import _ from "lodash";
import { NOTICE_PRODUCT_MESSAGE_API, NOTICE_SHIPPING_MESSAGE_API } from "../../utils/apiConstants";
import { put, takeLatest, call } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

const PRODUCT_COMMENT_MESSAGE_TYPE = 1;

function getNoticePartsAPICall(payload, isLoading) {
  const getNoticeDetailApiUrl =
    payload.payload.type === PRODUCT_COMMENT_MESSAGE_TYPE
      ? NOTICE_PRODUCT_MESSAGE_API + payload.payload.product_comment_message_id
      : NOTICE_SHIPPING_MESSAGE_API + payload.payload.shipping_message_id;

  return instance
    .get(getNoticeDetailApiUrl)
    .then((response) => {
      isLoading(false);
      return {
        message: "",
        data: _.get(response, "data.data", {}),
        success: true,
      };
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", "An error occurred"),
        data: {},
        success: false,
      };
    });
}

const getNoticeParts = function* (payload) {
  const isLoading = payload.payload.isLoading;
  isLoading(true);

  const response = yield call(getNoticePartsAPICall, payload, isLoading);
  const { success, message } = response;

  if (success) {
    yield put({
      type: GET_NOTICE_DETAIL_SAGA,
      payload: { payload: response },
    });
  } else {
    toastr.error("", message);
  }
};

function* NoticeDetailSaga() {
  yield takeLatest(GET_NOTICE_DETAIL, getNoticeParts);
}

export default NoticeDetailSaga;

import { QR_DATA, RESET_QR_DATA } from "./constants";

let initialState = {
  vehicleData: null,
};
const qrSearchReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case QR_DATA:
      actionState.vehicleData = action.payload;
      return actionState;
    case RESET_QR_DATA:
      actionState.vehicleData = action.payload;
      return actionState;
    default:
      return state;
  }
};

export { qrSearchReducer };

export const CREATE_CORPORATE = "view/createcorporate/create_corporate";
export const CREATE_CORPORATE_SAGA = "view/createcorporate/create_corporate_saga";
export const GET_INDUSTRY = "view/createcorporate/get_industry";
export const GET_INDUSTRY_SAGA = "view/createcorporate/get_industry_saga";
export const GET_PREFECTURE = "view/createcorporate/get_prefecture";
export const GET_PREFECTURE_SAGA = "view/createcorporate/get_prefecture_saga";
export const UPDATE_CORPORATE_SAGA = "view/createcorporate/update_corporate_saga";
export const FIND_PREFECTURE_SAGA = "view/createcorporate/find_prefecture_saga";
export const FIND_PREFECTURE = "view/createcorporate/find_prefecture";
export const CLEAR_PREFECTURES = "view/createcorporate/clear/prefecture/list";

export const SIGNUP_EMAIL = "view/signup/register/email";
export const PUT_SIGN_UP_EMAIL = "view/signup/put/email";
export const CLEAR_SIGNUP_DATA = "view/signup/clear/email";
export const SIGNUP_EMAIL_VERIFICATION = "view/signup/email/verification";
export const SIGNUP_COMPANY_FORM = "view/signup/input/form";
export const FIND_PREFECTURE_SAGA = "view/createcorporate/find_prefecture_saga";
export const GET_PREFECTURE_LIST = "view/signup/get/prefecture/list";
export const GET_PAYMENTDATE_LIST = "view/signup/get/paymentdate/list";
export const PUT_PREFECTURE_LIST = "view/signup/put/prefecture/list";
export const PUT_PAYMENTDATE_LIST = "view/signup/put/paymentdate/list";
export const PREFECTURE_OBJECT = "view/signup/put/prefecture/object";
export const SET_USER_TYPE = "view/signup/set/user/type";
export const SIGNUP_USER_FORM = "view/signup/input/user/form";
export const SIGNUP_FORM_CONFIRMATION = "view/signup/input/form/confirmation";
export const CLEAR_PREFECTURE_OBJECT = "view/signup/form/clear/prefecureObject";
export const CLEAR_CONFIRMATION_FORM = "view/signup/form/clear/confirmation/form";
export const PUT_VERIFIED_SIGN_UP_EMAIL = "view/signup/put/verified/email";
export const FORGOT_PASSWORD_REQUEST = "view/signup/request/forgot/password";
export const PASSWORD_RESET_REQUEST = "view/signup/request/password/reset/request";

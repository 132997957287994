import { put, takeLatest, call } from "redux-saga/effects";
import { GET_ORDER_HISTORY, PUT_ORDER_HISTORY, PUT_ORDER_HISTORY_DETAILS, GET_HISTORY_STATUS, GET_HISTORY_LIST } from "./constants";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { GET_HISTORY_STATUS_API, GET_ORDER_HISTORY_API } from "../../../utils/apiConstants";
import instance from "../../../utils/AxiosIntance";

function requestAPICall(API, payload, isLoading, requestMethod) {
  // Dynamic request method for API calls
  let req;
  if (requestMethod === "GET") {
    req = instance.get(API, { params: payload });
  } else if (requestMethod === "DELETE") {
    req = instance.delete(API, { params: payload });
  } else if (requestMethod === "PUT") {
    req = instance.put(API, payload);
  } else if (requestMethod === "POST") {
    req = instance.post(API, payload);
  }

  isLoading(true);
  return req
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getSagaOrderHistory = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  let putInfo = payload.payload.putInfo;
  let requestMethod = "GET";

  try {
    response = yield call(
      requestAPICall,
      GET_ORDER_HISTORY_API,
      payload.payload.data,
      isLoading,
      requestMethod
    );

    yield put({
      type: PUT_ORDER_HISTORY_DETAILS,
      payload: response,
    });
  } catch (error) {
    console.error("catch --->");
  }
};

function historyStatusAPICall() {
  return instance
    .get(GET_HISTORY_STATUS_API)
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getSagaOrderHistoryStatus = function* () {
  let response;
  try {
    response = yield call(historyStatusAPICall);
    if (_.get(response, "result", false)) {
      yield put({
        type: GET_HISTORY_LIST,
        payload: response.data,
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error(error);
  }
};

function* orderHistorySaga() {
  yield takeLatest(GET_ORDER_HISTORY, getSagaOrderHistory);
  yield takeLatest(GET_HISTORY_STATUS, getSagaOrderHistoryStatus);
}

export default orderHistorySaga;
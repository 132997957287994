export const GET_MASTER_PREFECTURE_INFO =
  "view/Shipping/Shipping/constants/GET_MASTER_PREFECTURE_INFO";
export const GET_MASTER_PREFECTURE_INFO_REDUCER =
  "view/Shipping/Shipping/constants/GET_MASTER_PREFECTURE_INFO_REDUCER";
export const GET_MASTER_PREFECTURE_INFO_REDUCER_RESET =
  "view/Shipping/Shipping/constants/GET_MASTER_PREFECTURE_INFO_REDUCER_RESET";
export const POST_USER_SHIPPING_DATA_DZONE_A20 =
  "view/Shipping/Parts/constants/POST_USER_SHIPPING_DATA_DZONE_A20";
export const PUT_USER_SHIPPING_DATA_DZONE_A21 =
  "view/Shipping/Parts/constants/PUT_USER_SHIPPING_DATA_DZONE_A21";
export const ROUTE_USER_SHIPPING_DATA_DZONE_A21_A23 =
  "view/Shipping/Shipping/constants/ROUTE_USER_SHIPPING_DATA_DZONE_A21_A23";
export const ROUTE_USER_SHIPPING_DATA_DZONE_A21_A23_RESET =
  "view/Shipping/Shipping/constants/ROUTE_USER_SHIPPING_DATA_DZONE_A21_A23_RESET";
export const PREFECTURE_INFO_DATA_DZONE =
  "view/Shipping/Shipping/constants/PREFECTURE_INFO_DATA_DZONE";
export const PREFECTURE_INFO_DATA_DZONE_REDUCER =
  "view/Shipping/Shipping/constants/PREFECTURE_INFO_DATA_DZONE_REDUCER";
export const GET_INFO_DZONE_SHIPPING_LIST_SAGA =
  "view/MyPage/MyPage/GET_INFO_DZONE_SHIPPING_LIST_SAGA";
export const GET_INFO_DZONE_SHIPPING_LIST_REDUCER_RESET =
  "view/MyPage/MyPage/GET_INFO_DZONE_SHIPPING_LIST_REDUCER_RESET";
export const GET_INFO_DZONE_PARTS_INFO_B5_SAGA =
  "view/Parts/PartsUtils/GET_INFO_DZONE_PARTS_INFO_B5_SAGA";
export const GET_INFO_DZONE_PARTS_INFO_B5_REDUCER =
  "view/Parts/PartsUtils/GET_INFO_DZONE_PARTS_INFO_B5_REDUCER";
export const GET_INFO_DZONE_PARTS_NO_B5_SAGA =
  "view/Parts/PartsUtils/GET_INFO_DZONE_PARTS_NO_B5_SAGA";
export const GET_INFO_DZONE_PARTS_NO_B5_REDUCER =
  "view/Parts/PartsUtils/GET_INFO_DZONE_PARTS_NO_B5_REDUCER";
export const GET_INFO_DZONE_PARTS_NO_B5_REDUCER_RESET =
  "view/Parts/PartsUtils/GET_INFO_DZONE_PARTS_NO_B5_REDUCER_RESET";
export const GET_INFO_DZONE_PARTS_COLOR_B5_SAGA =
  "view/Parts/PartsUtils/GET_INFO_DZONE_PARTS_COLOR_B5_SAGA";
export const GET_INFO_DZONE_PARTS_COLOR_B5_REDUCER =
  "view/Parts/PartsUtils/GET_INFO_DZONE_PARTS_COLOR_B5_REDUCER";
export const GET_INFO_DZONE_PARTS_LIST_INFO_B6_SAGA =
  "view/Parts/PartsUtils/GET_INFO_DZONE_PARTS_LIST_INFO_B6_SAGA";
export const GET_INFO_DZONE_PARTS_LIST_INFO_B6_REDUCER =
  "view/Parts/PartsUtils/GET_INFO_DZONE_PARTS_LIST_INFO_B6_REDUCER";
export const GET_INFO_DZONE_PARTS_LIST_INFO_B6_RESET_REDUCER =
  "view/Parts/PartsUtils/GET_INFO_DZONE_PARTS_LIST_INFO_B6_RESET_REDUCER";
export const GET_PARTS_LIST_INFO = "view/Parts/PartsUtils/constants/GET_PARTS_LIST_INFO";
export const SET_FAV = "view/Parts/PartsUtils/constants/SET_FAV";
export const SET_FAV_REDUCER = "view/Parts/PartsUtils/constants/SET_FAV_REDUCER";
export const DEL_FAV = "view/Parts/PartsUtils/constants/DEL_FAV";
export const DEL_FAV_REDUCER = "view/Parts/PartsUtils/constants/DEL_FAV_REDUCER";
export const TYPE_OF_SEARCH = "components/constants/TYPE_OF_SEARCH";
export const BODY_NUMBER_SEARCH = "components/constants/BODY_NUMBER_SEARCH";
export const EMPTY_BODY_NUMBER_SEARCH = "components/constants/EMPTY_BODY_NUMBER_SEARCH";

export const tempData = [
  {
    parts_id: 73,
    parts_name: "Ｒウインドガラス",
    parts_data: {
      main_component_id: 73,
      parts: [
        {
          parts_no: "TEST00056",
          feature_data: [
            {
              feature_1: "プライバシガラス",
              feature_2: "",
              feature_3: "",
              feature_4: "",
              feature_5: "",
              feature_6: "",
              feature_7: "",
              feature_8: "",
              feature_9: "",
              feature_10: "",
            },
          ],
          element_data: [
            {
              element_1: null,
              element_2: null,
              element_3: null,
              element_4: null,
              element_5: null,
              element_6: null,
              element_7: null,
              element_8: null,
              element_9: null,
              element_10: null,
            },
          ],
        },
      ],
      component_id_1: null,
      component_id_2: null,
      component_id_3: null,
      component_id_4: null,
      component_id_5: null,
      component_id_6: null,
      component_id_7: null,
      component_id_8: null,
      component_id_9: null,
      component_id_10: null,
    },
  },
  {
    parts_id: 74,
    parts_name: "バックドアＡＳＳＹ",
    parts_data: {
      main_component_id: 75,
      parts: [
        {
          parts_no: "TEST00029",
          feature_data: [
            {
              feature_1: "カメラ無し",
              feature_2: "スポイラー付き",
              feature_3: "スマートキー無し",
              feature_4: "ワイパー付き",
              feature_5: "ハイマウントランプ付き",
              feature_6: "パワーテールゲート無し",
              feature_7: "",
              feature_8: "",
              feature_9: "",
              feature_10: "",
            },
          ],
          element_data: [
            {
              element_1: "1:ガラス無,2:ガラス有",
              element_2: null,
              element_3: null,
              element_4: null,
              element_5: null,
              element_6: null,
              element_7: null,
              element_8: null,
              element_9: null,
              element_10: null,
            },
          ],
        },
        {
          parts_no: "TEST00040",
          feature_data: [
            {
              feature_1: "カメラ無し",
              feature_2: "スポイラー付き",
              feature_3: "スマートキー付き",
              feature_4: "ワイパー付き",
              feature_5: "ハイマウントランプ付き",
              feature_6: "パワーテールゲート無し",
              feature_7: "",
              feature_8: "",
              feature_9: "",
              feature_10: "",
            },
          ],
          element_data: [
            {
              element_1: "1:ガラス無,2:ガラス有",
              element_2: null,
              element_3: null,
              element_4: null,
              element_5: null,
              element_6: null,
              element_7: null,
              element_8: null,
              element_9: null,
              element_10: null,
            },
          ],
        },
        {
          parts_no: "TEST00041",
          feature_data: [
            {
              feature_1: "カメラ付き",
              feature_2: "スポイラー付き",
              feature_3: "スマートキー無し",
              feature_4: "ワイパー付き",
              feature_5: "ハイマウントランプ付き",
              feature_6: "パワーテールゲート無し",
              feature_7: "",
              feature_8: "",
              feature_9: "",
              feature_10: "",
            },
          ],
          element_data: [
            {
              element_1: "1:Bガラス無,2:Bガラス有",
              element_2: null,
              element_3: null,
              element_4: null,
              element_5: null,
              element_6: null,
              element_7: null,
              element_8: null,
              element_9: null,
              element_10: null,
            },
          ],
        },
        {
          parts_no: "TEST00045",
          feature_data: [
            {
              feature_1: "カメラ付き",
              feature_2: "スポイラー付き",
              feature_3: "スマートキー付き",
              feature_4: "ワイパー付き",
              feature_5: "ハイマウントランプ付き",
              feature_6: "パワーテールゲート無し",
              feature_7: "",
              feature_8: "",
              feature_9: "",
              feature_10: "",
            },
          ],
          element_data: [
            {
              element_1: "1:Cガラス無,2:Cガラス有",
              element_2: null,
              element_3: null,
              element_4: null,
              element_5: null,
              element_6: null,
              element_7: null,
              element_8: null,
              element_9: null,
              element_10: null,
            },
          ],
        },
      ],
      component_id_1: 73,
      component_id_2: null,
      component_id_3: null,
      component_id_4: null,
      component_id_5: null,
      component_id_6: null,
      component_id_7: null,
      component_id_8: null,
      component_id_9: null,
      component_id_10: null,
    },
  },
];

import ENVS from "../constants/envs";

export const SIGNUP_EMAIL_API = "/user/email/register";
export const SIGN_IN_MY_PAGE_API = "/user/login";
export const SIGNUP_EMAIL_VERIFICATION_API = "/user/email/verification";
export const USER_MYPAGE_INFO_A12_API = "/user/mypage/info";
export const USER_MYPAGE_INFO_A12_IMAGE_API = "user/info/update_image";
export const SIGNUP_FORM_API = "/user/info/register";
export const COMPANT_FIND_ZIP_CODE_API = "/master/prefecture/data";
export const PREFECTURE_LIST_API = "/master/prefecture/info";
export const PAYMENTDATE_LIST_API = "/master/payment_date";
export const SIGNUP_USER_FORM_API = "/user/info/register";
export const POST_USER_SHIPPING_A20_API = "/user/shipping/register";
export const PUT_USER_SHIPPING_A21_API = "/user/shipping/update";
export const DELETE_USER_SHIPPING_API = "/user/shipping";
export const PUT_USER_SHEPPING_DEFAULT_API = "/user/shipping/default";
export const PUT_USER_INCHARGE_A22_API = "/user/incharge/update";
export const POST_USER_INCHARGE_A22_API = "/user/incharge/register";
export const DELETE_INCHARGE_A22_A12_API = "/user/incharge/delete";
export const FORGOT_PASSWORD_API = "/user/login/reset_password_email";
export const RESER_PASSWORD_API = "/user/login/reset_password";
export const UPDATE_MYPROFILE_API = "/user/info/update";
export const UPDATE_EMAIL_API = "/user/info/update_email";
export const UPDATE_EMAIL_VERIFICATION_API = "/user/info/verification_email";
export const GET_PREFECTURE = "/master/prefecture/info";
export const GET_INCHARGELIST_A22 = "/user/incharge/list";
export const GET_SHIPPING_LIST_A22 = "/user/shipping/list";
export const GET_MAKER_LIST_API = "/master/makers/info";
export const GET_CAR_LIST_API = "/master/cars/info";
export const GET_CAR_MODEL_LIST_API = "/master/car_models/info";
export const GET_MODEL_NO_API = "/search/vehicle_no/info";
export const POST_PRODUCT_TO_CART_API = "/order/cart/register";
export const POST_PRODUCT_COMMENT_API = "/search/product_comments/register";
export const GET_PRODUCT_COMMENTS_API = "/search/product_comments/info";
export const POST_CART_API = "/order/cart/checkout";
export const GET_PRE_CHECKOUT_PROCESS = "/order/cart/pre_checkout_process";
export const GET_SUGGESTED_PRODUCTS_API = "/search/parts_same_vin/info";
export const GET_SUGGESTED_PRODUCTS_FOR_USER_API = "/search/parts_same_vin/info_for_user";
export const GET_FAVOURITE_PRODUCTS_API = "/search/favorite/info";
export const DELETE_FAVOURITE_PRODUCT_API = "/search/favorite/update";
export const ADD_FAVOURITE_PRODUCT_API = "/search/favorite/register";
export const SEARCH_PARTS_B5_API = "/master/parts/info";
export const GET_REQUEST_PARTS_INFO_API = "/master/parts/info";
export const REGISTER_PART_INQUIRIES_API = "/search/part_inquiries/register";
export const GET_REQUEST_PARTS_NO_API = "/master/part_no/info";
export const GET_CARD_DETAILS_API = "/order/card/list";
export const ADD_CARD_DETAILS_API = "/order/card/register";
export const DELETE_CARD_API = "/order/card/delete";
export const SET_CARD_DEFUALT_API = "order/card/set_default_card";
export const GET_COLORS_API = "master/colors/info";
export const GET_PARTS_LIST_INFO_API = "parts/list/info";
export const GET_PARTS_LIST_INFO_FOR_USER_API = "parts/list/info_for_user";
export const GET_PARTS_LIST_INFO_API_WITH_MODEL_SPEC = "search/items/info";
export const GET_PARTS_LIST_INFO_API_WITH_MODEL_SPEC_FOR_USER_API = "search/items/info_for_user";
export const GET_PARTS_LIST_BY_GENUINE_PART_NUMBER = "search/genuine_parts_no";
export const GET_PARTS_LIST_BY_GENUINE_PART_NUMBER_FOR_USER_API =
  "search/genuine_parts_no_for_user";
export const REGISTER_MYPAGE_WITHDRAWAL_API = "user/withdrawal";
export const HOME_PAGE_FAVORITE_PARTS_API = "search/parts/favorite_list";
export const HOME_PAGE_CART_PARTS_API = "search/parts/cart_list";
export const HOME_PAGE_API = "search/parts/list";
export const HOME_PAGE_FOR_USER_API = "search/parts/list_for_user";
export const GET_CART_INFO_API = "/order/cart/info";
export const GET_CART_USER_INFO_API = "/order/cart/user_info";
export const GET_CART_INFO_DEL_API = "/order/cart/delete";
export const GET_CART_INFO_UPDATE_API = "/order/cart/update";
export const GET_ORDER_HISTORY_API = "order/history/info";
export const GET_HISTORY_STATUS_API = "user/shipping/status_list";
export const POST_INQUIRY_REGISTER_API = "/inquiries";
export const GET_NOTICE_INFO_API = "/notices";
export const GET_NOTICE_READ_COUNT_API = "/notices/status/unread_count";
export const NOTICE_PRODUCT_MESSAGE_API = "/notices/product_message/";
export const NOTICE_SHIPPING_MESSAGE_API = "/notices/shipping_message/";
export const GET_MYPAGE_REASON_API = "/master/reasonwithdrawal/list";
export const GET_PRODUCT_DETAILS_API = "/parts/detail/info";
export const GET_PRODUCT_DETAILS_FOR_USER_API = "/parts/detail/info_for_user";
export const POST_PRODUCT_COMMETS_API = "/search/product_comments/register";
export const FETCH_CAUTION_PLATE_API = `${ENVS.CAUTION_PLATE_API}/get_plate_data`;
export const POST_CAUTION_PLATE_API = `${ENVS.CAUTION_PLATE_API}/put_plate_image`;
export const POST_FIXED_DATA_API = `${ENVS.CAUTION_PLATE_API}/put_fixed_data`;
export const ADD_FV_API = "/search/favorite/register";
export const DEL_FV_API = "/search/favorite/update";
export const GET_DELV_LIST_API = "/order/cart/delivery_datelist";
export const GET_POSTAGE_API = "/search/items/postage";
export const GET_ORDERDET_API = "search/card/order";
export const GET_ORDER_HISTORY_DETAIL_API = "order/history/detail";
export const DELETE_CART_INFO_DELETE_API = "/order/cart/unsalable_item";
export const GET_CAUTIONPLATE_API = "search/caution_plate/car_info";
export const GET_STRIPE_INFO_API = "order/card/setup_intent";

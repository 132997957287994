import instance from "../../utils/AxiosIntance";
import {
  DELETE_MODEL_NO_SAGA,
  GET_MODEL_NO,
  PUT_MODEL_NO_SAGA,
  PUT_SEARCH_REQUEST_ACTION,
  PUT_SEARCH_REQUEST_SAGA,
} from "./constants";
import _ from "lodash";
import { GET_MODEL_NO_API } from "../../utils/apiConstants";
import { toastr } from "react-redux-toastr";
import { put, takeLatest, call } from "redux-saga/effects";

let response;

async function searchModelNoAPICall(payload) {
  return instance
    .get(GET_MODEL_NO_API, {
      params: payload.payload,
    })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      toastr.error(
        "入力された車輛は、現在対応しておりません。随時、対応する車輌を増やします。",
        _.get(response, "message")
      );
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const searchModelNo = function* (payload) {
  try {
    const response = yield call(searchModelNoAPICall, payload);
    if (_.get(response, "result", false) && response?.data?.length !== 0) {
      if (response?.data?.length === 0) {
        toastr.error("", _.get(response, "message"));
      }
      yield put({
        type: PUT_MODEL_NO_SAGA,
        payload: _.get(response, "data"),
      });
    } else {
      yield put({
        type: DELETE_MODEL_NO_SAGA,
        payload: null,
      });
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("catch --->");
  }
};

const putSearchRequest = function* (payload) {
  try {
    yield put({
      type: PUT_SEARCH_REQUEST_SAGA,
      payload: payload.payload.payload,
    });
  } catch (error) {
    console.error("catch --->");
  }
};

function* searchSaga() {
  yield takeLatest(GET_MODEL_NO, searchModelNo);
  yield takeLatest(PUT_SEARCH_REQUEST_ACTION, putSearchRequest);
}

export default searchSaga;

import { put, takeLatest, call } from "redux-saga/effects";
import {
  GET_INFO_DZONE_MY_PAGE_SAGA,
  GET_INFO_DZONE_MY_PAGE__REDUCER,
  INFO_SET_PROFILE_DZONE_MY_PAGE__REDUCER,
  UPDATE_INCHARGE_A22,
  UPDATE_INCHARGE_A22_REDUCER,
  DELETE_INCHARGE_A22_A12,
  POST_INCHARGE_A22,
  GET_INFO_DZONE_INCHARGE_LIST_SAGA,
  GET_INFO_DZONE_INCHARGE_LIST_REDUCER,
  GET_INFO_DZONE_SHIPPING_LIST_SAGA,
  GET_INFO_DZONE_SHIPPING_LIST_REDUCER,
  UPDATE_ERROR_MESSAGE,
  PUT_SHIPPING_AS_DEFAULT,
  PUT_SHIPPING_AS_DEFAULT_REDUCER,
} from "./constants";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import {
  USER_MYPAGE_INFO_A12_API,
  USER_MYPAGE_INFO_A12_IMAGE_API,
  PUT_USER_INCHARGE_A22_API,
  DELETE_INCHARGE_A22_A12_API,
  POST_USER_INCHARGE_A22_API,
  GET_INCHARGELIST_A22,
  GET_SHIPPING_LIST_A22,
  PUT_USER_SHEPPING_DEFAULT_API,
} from "../../../../utils/apiConstants";
import instance from "../../../../utils/AxiosIntance";
import { PlayLessonSharp } from "@mui/icons-material";
function userMyPageInfoHandler() {
  return instance
    .get(USER_MYPAGE_INFO_A12_API)
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
        status: _.get(error, "response.status", 0),
      };
    });
}

/* API calls END  */
const getMyPageInfoHandler = function* (payload) {
  let response;
  try {
    response = yield call(userMyPageInfoHandler, payload.payload.params);
    yield put({
      type: GET_INFO_DZONE_MY_PAGE__REDUCER,
      payload: {
        data: _.get(response, "data"),
        type: _.get(response, "data.user.type"),
      },
    });
  } catch (error) {
    toastr.error("エラーが発生しました。");
  } finally {
    //isLoading(false)
  }
};

async function userMyPageInfoImageHandler(data, isLoading) {
  isLoading(true);
  try {
    const response = await instance.put(USER_MYPAGE_INFO_A12_IMAGE_API, data);
    isLoading(false);
    return _.get(response, "data", {});
  } catch (error) {
    isLoading(false);
    return {
      message: _.get(error, "response.data.message", {}),
      success: false,
    };
  }
}

const setMyPageInfoProfileHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  try {
    const response = yield call(userMyPageInfoImageHandler, payload.payload.data, isLoading);
    if (_.get(response, "result", false)) {
      toastr.success("", _.get(response, "message"));
      let t = { time: new Date(), image: response.data.image };
      yield put({
        type: UPDATE_INCHARGE_A22_REDUCER,
        payload: t,
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  }
};

function upDateInChargeHandlerFunc(data, isLoading, flg) {
  if (flg) {
    return instance
      .post(POST_USER_INCHARGE_A22_API, data)
      .then((response) => {
        return _.get(response, "data", {});
      })
      .catch((error) => {
        return {
          message: _.get(error, "response.data.message", {}),
          success: false,
        };
      });
  } else {
    return instance
      .put(PUT_USER_INCHARGE_A22_API, data)
      .then((response) => {
        return _.get(response, "data", {});
      })
      .catch((error) => {
        return {
          message: _.get(error, "response.data.message", {}),
          success: false,
        };
      });
  }
}

const upDateInChargeHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let response;
  try {
    response = yield call(upDateInChargeHandlerFunc, payload.payload.data, isLoading, false);
    if (_.get(response, "result", false)) {
      toastr.success("", _.get(response, "message"));
      let t = new Date();
      yield put({
        type: UPDATE_INCHARGE_A22_REDUCER,
        payload: t,
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  }
};

const postInChargeHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let response;
  try {
    response = yield call(upDateInChargeHandlerFunc, payload.payload.data, isLoading, true);
    if (_.get(response, "result", false)) {
      toastr.success("", _.get(response, "message"));
      let t = new Date();
      yield put({
        type: UPDATE_INCHARGE_A22_REDUCER,
        payload: t,
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error(error);

    toastr.error("エラーが発生しました。");
  }
};

function deleteInChargeHandlerFunc(data, isLoading) {
  isLoading = true;
  return instance
    .put(DELETE_INCHARGE_A22_A12_API, data)
    .then((response) => {
      isLoading = false;
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading = false;
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const deleteInChargeHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let history = payload.payload.history;

  try {
    const response = yield call(deleteInChargeHandlerFunc, payload.payload.data, isLoading);
    if (_.get(response, "result", false)) {
      toastr.success("", _.get(response, "message"));
      let t = new Date().getDate();
      yield put({
        type: UPDATE_INCHARGE_A22_REDUCER,
        payload: t,
      });
    } else {
      yield put({
        type: UPDATE_ERROR_MESSAGE,
        payload: _.get(response, "message"),
      });
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  }
};

function getInChargeListHandlerFunc(data, isLoading) {
  isLoading(true);
  return instance
    .get(GET_INCHARGELIST_A22, { params: data })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getInChargeListHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let history = payload.payload.history;
  let response;
  try {
    response = yield call(getInChargeListHandlerFunc, payload.payload.data, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: GET_INFO_DZONE_INCHARGE_LIST_REDUCER,
        payload: _.get(response, "in_charge"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  } finally {
    isLoading(false);
  }
};

function getShippingListHandlerFunc(data, isLoading) {
  isLoading(true);
  return instance
    .get(GET_SHIPPING_LIST_A22, { params: data })
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getShippingListHandler = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let history = payload.payload.history;
  let response;
  try {
    response = yield call(getShippingListHandlerFunc, payload.payload.data, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: GET_INFO_DZONE_SHIPPING_LIST_REDUCER,
        payload: _.get(response, "data.shipping"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("エラーが発生しました。");
  } finally {
    isLoading(false);
  }
};

const putShippingAsDefaultHandlerFunc = async (payload) => {
  const data = payload.data;
  try {
    const response = await instance.put(PUT_USER_SHEPPING_DEFAULT_API, {
      company_shipping_id: data,
    });
    return _.get(response, "data", {});
  } catch (error) {
    return {
      message: _.get(error, "response.data.message", {}),
      success: false,
    };
  }
};

const putShippingAsDefaultHandler = function* (payload) {
  const isLoading = payload.payload.isLoading;
  isLoading(true);
  let response;
  let data = payload.payload.data;
  try {
    response = yield call(putShippingAsDefaultHandlerFunc, { data });
    if (_.get(response, "result", true)) {
      yield put({
        type: PUT_SHIPPING_AS_DEFAULT_REDUCER,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("catch -->", error);
  } finally {
    isLoading(false);
  }
};

function* myPageInfoSaga() {
  yield takeLatest(GET_INFO_DZONE_MY_PAGE_SAGA, getMyPageInfoHandler);
  yield takeLatest(INFO_SET_PROFILE_DZONE_MY_PAGE__REDUCER, setMyPageInfoProfileHandler);
  yield takeLatest(UPDATE_INCHARGE_A22, upDateInChargeHandler);
  yield takeLatest(POST_INCHARGE_A22, postInChargeHandler);
  yield takeLatest(DELETE_INCHARGE_A22_A12, deleteInChargeHandler);
  yield takeLatest(GET_INFO_DZONE_INCHARGE_LIST_SAGA, getInChargeListHandler);
  yield takeLatest(GET_INFO_DZONE_SHIPPING_LIST_SAGA, getShippingListHandler);
  yield takeLatest(PUT_SHIPPING_AS_DEFAULT, putShippingAsDefaultHandler);
}

export default myPageInfoSaga;

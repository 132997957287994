import {
  GET_INFO_DZONE_MY_PAGE__REDUCER,
  MOBILE_VIEW_INFO_DZONE_MY_PAGE__REDUCER,
  UPDATE_INCHARGE_A22_REDUCER,
  GET_INFO_DZONE_INCHARGE_LIST_REDUCER,
  GET_INFO_DZONE_INCHARGE_LIST_REDUCER_RESET,
  GET_INFO_DZONE_SHIPPING_LIST_REDUCER,
  GET_INFO_DZONE_SHIPPING_LIST_REDUCER_RESET,
  UPDATE_ERROR_MESSAGE,
  PUT_SHIPPING_AS_DEFAULT_REDUCER,
} from "./constants";

let initialState = {
  myPageInfo: {},
  type: null,
  myPageSpTop: true,
  flag: null,
  inChargeList: [],
  shippingList: [],
  error_message: null,
};
const myPageInfoReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case GET_INFO_DZONE_MY_PAGE__REDUCER:
      return {
        ...state,
        myPageInfo: action.payload.data,
        type: action.payload.type,
      };
    case MOBILE_VIEW_INFO_DZONE_MY_PAGE__REDUCER:
      actionState.myPageSpTop = !actionState.myPageSpTop;
      return actionState;
    case UPDATE_ERROR_MESSAGE:
      actionState.error_message = action.payload;
      return actionState;
    case UPDATE_INCHARGE_A22_REDUCER:
      actionState.flag = action.payload.time;
      actionState.myPageInfo.user.image = action.payload.image;
      return actionState;
    case GET_INFO_DZONE_INCHARGE_LIST_REDUCER:
      actionState.flag = action.payload;
      actionState.inChargeList = action.payload;
      return actionState;
    case GET_INFO_DZONE_INCHARGE_LIST_REDUCER_RESET:
      actionState.inChargeList = [];
      return actionState;
    case GET_INFO_DZONE_SHIPPING_LIST_REDUCER:
      return {
        ...state,
        flag: action.payload,
        shippingList: action.payload,
        myPageInfo: {
          ...state.myPageInfo,
          shipping: action.payload,
        },
      };
    case GET_INFO_DZONE_SHIPPING_LIST_REDUCER_RESET:
      actionState.shippingList = [];
      return actionState;
    case PUT_SHIPPING_AS_DEFAULT_REDUCER:
      //IDが同じなら既定の配送先判定を変更
      const updateShippingStatuses = (shippingList, defaultShippingId, nonDefaultShippingId) => {
        return shippingList.map((shipping) => {
          if (shipping.shipping_id === defaultShippingId) {
            return { ...shipping, is_default: 1 };
          }
          if (shipping.shipping_id === nonDefaultShippingId) {
            return { ...shipping, is_default: 0 };
          }
          return shipping;
        });
      };

      return {
        ...state,
        myPageInfo: {
          ...state.myPageInfo,
          shipping: updateShippingStatuses(
            state.myPageInfo.shipping,
            action.payload.default_shipping.shipping_id,
            action.payload.shipping_changed_to_nondefault.shipping_id
          ),
        },
      };
    default:
      return actionState;
  }
};

export { myPageInfoReducer };

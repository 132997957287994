import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const useLocationChange = (callback) => {
  const refCallback = useRef();
  const location = useLocation();
  useEffect(() => {
    refCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (refCallback.current) {
      refCallback.current(location);
    }
  }, [location]);
};

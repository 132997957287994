import { put, takeLatest } from "redux-saga/effects";
import { CREATE_CORPORATE_SAGA } from "./constants";
import _ from "lodash";

/* API calls END  */
const createCorporate = function* (payload) {};

function* registerFormSaga() {
  yield takeLatest(CREATE_CORPORATE_SAGA, createCorporate);
}

export default registerFormSaga;

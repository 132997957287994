import instance from "../../../../utils/AxiosIntance";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { USER_MYPAGE_INFO_A12_API } from "../../../../utils/apiConstants";
import { getCookie } from "../../../../utils/cookieUtils";

//ユーザー情報取得
const getMyPageInfo = async () => {
  if (!getCookie("access_token")) return {};

  try {
    const response = await instance.get(USER_MYPAGE_INFO_A12_API);
    return _.get(response, "data.data", {});
  } catch (error) {
    return {
      message: _.get(error, "response.data.message", {}),
      success: false,
    };
  }
};

export const useGetMyPageInfo = () => {
  return useQuery({
    queryKey: [USER_MYPAGE_INFO_A12_API],
    queryFn: getMyPageInfo,
  });
};

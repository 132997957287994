import { styled, useTheme } from "@mui/material/styles";
import { TextField, InputBase } from "@mui/material";

// Input in use at multiple screen of Phase A & B
export const CheckoutCustomInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "8px",
    backgroundColor: "rgba(61, 61, 61, 1)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    fontSize: "14px",
    height: "18px",
    padding: "12px 16px",
    color: "rgba(255, 255, 255, 1)",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:focus": {
      color: "rgba(255, 255, 255, 1)",
      border: "1px solid rgba(230, 92, 92, 0.8)",
    },
    "&::placeholder": {
      fontSize: "14px",
      opacity: "40%",
      color: "rgba(255, 255, 255, 1)",
    },
  },
}));

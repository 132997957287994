import {
  CLEAR_CONFIRMATION_FORM,
  CLEAR_PREFECTURE_OBJECT,
  CLEAR_SIGNUP_DATA,
  PREFECTURE_OBJECT,
  PUT_PREFECTURE_LIST,
  PUT_PAYMENTDATE_LIST,
  PUT_SIGN_UP_EMAIL,
  PUT_VERIFIED_SIGN_UP_EMAIL,
  SET_USER_TYPE,
  SIGNUP_FORM_CONFIRMATION,
} from "./constants";
import _ from "lodash";
let initialState = {
  signupEmail: null,
  prefectureList: null,
  prefectureObject: null,
  paymentDateList: null,
  userType: null,
  signupFormConfirmation: null,
};
const signupReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case PUT_SIGN_UP_EMAIL:
      actionState.signupEmail = _.get(action, "payload.email", "");
      return actionState;
    case PUT_VERIFIED_SIGN_UP_EMAIL:
      actionState.signupEmail = _.get(action, "payload", "");
      return actionState;
    case PUT_PREFECTURE_LIST:
      actionState.prefectureList = _.get(action, "payload", []);
      return actionState;
    case PUT_PAYMENTDATE_LIST:
      actionState.paymentDateList = _.get(action, "payload", []);
      return actionState;
    case PREFECTURE_OBJECT:
      actionState.prefectureObject = _.get(action, "payload", {});
      return actionState;
    case SET_USER_TYPE:
      actionState.userType = action.payload;
      return actionState;
    case CLEAR_PREFECTURE_OBJECT:
      actionState.prefectureObject = null;
      return actionState;
    case SIGNUP_FORM_CONFIRMATION:
      actionState.signupFormConfirmation = action.payload;
      return actionState;
    case CLEAR_CONFIRMATION_FORM:
      actionState.signupFormConfirmation = null;
      return actionState;
    case CLEAR_SIGNUP_DATA:
      actionState.signupEmail = null;
      actionState.prefectureList = null;
      actionState.prefectureObject = null;
      actionState.userType = null;
      actionState.signupFormConfirmation = null;
      return actionState;
    default:
      return state;
  }
};

export { signupReducer };

import _ from "lodash";
import {
  DISABLE_PRODUCT_CART_POPUP_STATE_SAGA,
  PUT_PRODUCT_COMMENTS_SAGA,
  PUT_PRODUCT_TO_CART_SAGA,
  INITIALIZE_COMMENTS,
} from "./constants";
let initialState = {
  isProductAddedToCart: false,
  productComments: [],
};

// state 初期化
const clearState = () => {
  return {
    type: "CLEAR_STATE",
  };
};
const productPreviewReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case PUT_PRODUCT_TO_CART_SAGA:
      return { ...actionState, isProductAddedToCart: action.payload.result };
    case DISABLE_PRODUCT_CART_POPUP_STATE_SAGA:
      return { ...actionState, isProductAddedToCart: false };
    case PUT_PRODUCT_COMMENTS_SAGA:
      let data = action.payload.payload.data;
      return {
        ...actionState,
        productComments: data,
      };
    case INITIALIZE_COMMENTS:
      // productCommentsパラメータを初期化する関数
      // 別のページに遷移した際の前state浄化のため使用。
      return { ...actionState.isProductAddedToCart, productComments: [] };
    default:
      return state;
  }
};
export { productPreviewReducer };

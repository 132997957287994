import { Close } from "@mui/icons-material";

export const MyCustomToast = ({ appearance, children }) => (
  <div
    className="ToastView"
    style={{
      background: appearance === "error" ? "rgba(229, 92, 92, 1)" : "rgba(110, 212, 157, 1)",
    }}
  >
    <div className="icon mx-2">
      <Close className="font-size-20" />
    </div>
    <div className="textStyle">{children}</div>
  </div>
);

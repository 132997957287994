const getEnvVariable = (key: string, defaultValue?: string): string => {
  const value = process.env[key];
  if (value === undefined) {
    console.warn(`Environment variable ${key} is not set.`);
    return defaultValue ?? "";
  }
  return value;
};

const ENV = getEnvVariable("REACT_APP_ENV");
const IS_PRODUCTION = ENV === "production";
const CMS_API = getEnvVariable("REACT_APP_CMS_API");

const ENVS = {
  ENV,
  IS_PRODUCTION,
  API_END_POINT: getEnvVariable("REACT_APP_API_END_POINT"),
  CAUTION_PLATE_API: getEnvVariable("REACT_APP_CAUTION_PLATE_API"),
  CMS_NEWS_API: IS_PRODUCTION ? `${CMS_API}/news` : `${CMS_API}/news_test`,
  AWS_ACCESS_KEY: getEnvVariable("REACT_APP_AWS_ACCESS_KEY"),
  AWS_SECRET_KEY: getEnvVariable("REACT_APP_AWS_SECRET_KEY"),
  AWS_REGION: getEnvVariable("REACT_APP_AWS_REGION"),
  AWS_BUCKET: getEnvVariable("REACT_APP_AWS_BUCKET"),
  STRIPE_KEY: getEnvVariable("REACT_APP_STRIPE_KEY"),
  GTM_KEY: getEnvVariable("REACT_APP_GTM_KEY"),
  G_KEY: getEnvVariable("REACT_APP_G_KEY"),
};

export default ENVS;

import { Grid, Typography } from "@mui/material";
import { CheckoutCustomButton } from "../PhaseBStyles/style";
import { formatPinCode } from "../../utils/utils";

export function ShippingInfo(props) {
  const { shippingInfo } = props;

  return (
    shippingInfo && (
      <Grid container>
        <Grid item xs={12} sm={8} md={8}>
          <Typography variant="p" component="p" className="font-size-14">
            {shippingInfo?.name ?? ""}
          </Typography>
          <Typography variant="p" component="p" className="font-size-14">
            〒 {shippingInfo && formatPinCode(shippingInfo.postal_code)}
          </Typography>
          <Typography variant="p" component="p" className="font-size-14">
            {shippingInfo?.prefecture ?? ""}
            {shippingInfo?.city ?? ""}
            {shippingInfo?.address ?? ""}
            {shippingInfo?.buildin ?? ""}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} className="checkout-btn">
          <CheckoutCustomButton
            className="edit-button font-weight-700 font-size-14"
            variant="contained"
            fullWidth
            onClick={props.shippingPage}
            data-cy="selectAnotherShippingButton"
          >
            {"別の住所に送る"}
          </CheckoutCustomButton>
        </Grid>
      </Grid>
    )
  );
}

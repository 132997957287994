import { GET_CAUTION_PLATE, PUT_CAUTION_PLATE, RESET_CAUTION_PLATE } from "./cautionPlateConstants";

let initialState = {
  cautionPlate: null,
  error: false,
};

const cautionPlateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAUTION_PLATE:
      return {
        ...state,
        cautionPlate: action.payload,
        error: action.error,
      };
    case PUT_CAUTION_PLATE:
      return {
        ...state,
        cautionPlate: action.payload,
        error: action.error,
      };
    case RESET_CAUTION_PLATE:
      return {
        ...state,
        cautionPlate: null,
        error: false,
      };
    default:
      return state;
  }
};

export { cautionPlateReducer };

import {
  GET_CARD_DETAILS,
  ADD_CARD_DETAILS,
  ADD_CARD_DETAILS_STATUS,
  PUT_CARD_DETAILS,
  PUT_CARD_AS_DEFAULT,
  DELETE_CARD,
} from "./constants";

/* Get card details */
export function getCardDetails(payload) {
  return {
    type: GET_CARD_DETAILS,
    payload: payload,
  };
}

/* Add new card details */
export function addCardDetails(payload) {
  return {
    type: ADD_CARD_DETAILS,
    payload: payload,
  };
}

/* Put card as default */
export function addCardDetailsStatus(payload) {
  return {
    type: ADD_CARD_DETAILS_STATUS,
    payload: payload,
  };
}

/* Put card details */
export function putCardDetails(payload) {
  return {
    type: PUT_CARD_DETAILS,
    payload: payload,
  };
}

/* Put card as default */
export function putCardAsDefault(payload) {
  return {
    type: PUT_CARD_AS_DEFAULT,
    payload: payload,
  };
}

/* Delete card details */
export function deleteCard(payload) {
  return {
    type: DELETE_CARD,
    payload: payload,
  };
}

import { DELETE_MODEL_NO_SAGA, PUT_MODEL_NO_SAGA, PUT_SEARCH_REQUEST_SAGA } from "./constants";
let initialState = {
  makersList: null,
  modelNo: null,
  searchRequest: null,
};
const searchReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case PUT_MODEL_NO_SAGA:
      // actionState.modelNo = action.payload;
      return { ...actionState, modelNo: action.payload };
    case DELETE_MODEL_NO_SAGA:
      actionState.modelNo = null;
      return actionState;
    case PUT_SEARCH_REQUEST_SAGA:
      actionState.searchRequest = action.payload;
      return { ...actionState };
    default:
      return state;
  }
};

export { searchReducer };

import {
  ADD_CARD_DETAILS_STATUS,
  PUT_CARD_DETAILS,
  PUT_SELECTED_CARD_AS_DEFAULT,
  PUT_CARD_DETAILS_RESET,
} from "./constants";

let initialState = {
  card: null,
  isDefault: null,
  isProccessCompleted: null,
};
const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_CARD_DETAILS:
      return {
        ...state,
        card: action.payload,
      };
    case PUT_CARD_DETAILS_RESET:
      return {
        ...state,
        card: null,
      };
    case PUT_SELECTED_CARD_AS_DEFAULT:
      return {
        ...state,
        isDefault: action.payload,
      };
    case ADD_CARD_DETAILS_STATUS:
      return {
        ...state,
        isProccessCompleted: action.payload.completed,
      };
    default:
      return state;
  }
};

export { cardReducer };

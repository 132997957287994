import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Typography,
  MenuItem,
  Select,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";
import { FiX } from "react-icons/fi";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "lodash";

const BootstrapGreySelect = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    height: "auto",
    width: "6rem",
    padding: "0.75rem",
    fontSize: "1rem",
    color: "rgba(255, 255, 255, 0.8)",
    background: "rgba(61, 61, 61, 1)",
    border: "none",
    borderRadius: "0.5rem !important",
  },
  "&:focus": {
    border: "2px solid rgba(255, 255, 255, 0.8)",
  },
}));

export default function CartDeliveryDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const handleClickOpen = () => {
    props.handleClickOpen();
  };

  const handleClose = () => {
    props.handleClose();
  };

  const [openFullWidth, setOpenFullWidth] = React.useState(props.open);
  const handleClickOpenFullWidth = () => {
    setOpenFullWidth(true);
  };

  const handleCloseFullWidth = () => {
    setOpenFullWidth(false);
  };

  const names = [
    { value: 1, jpText: "北海道" },
    { value: 2, jpText: "東北" },
    { value: 3, jpText: "関東" },
    { value: 4, jpText: "北陸・甲信越" },
    { value: 5, jpText: "東海" },
    { value: 6, jpText: "近畿" },
    { value: 7, jpText: "中国" },
    { value: 8, jpText: "四国" },
    { value: 9, jpText: "九州" },
    { value: 10, jpText: "沖縄" },
  ];
  var groupedItems = [];

  const [area, setArea] = useState("北海道");
  if (props.list) {
    groupedItems = _.groupBy(props.list.postage_info, "area_name");
  }
  const [cartItem, setCartItem] = React.useState(groupedItems ? groupedItems["北海道"] : []);
  const handleChange = (event) => {
    setArea(event.target.value);
    setCartItem(groupedItems[event.target.value]);
  };
  useEffect(() => {
    setCartItem(groupedItems["北海道"]);
  }, [props.list]);

  return (
    <>
      <div className="">
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-popup shipping-charge-popup"
          sx={{ display: { xs: "none", md: "block" } }}
          data-cy={"postageModal"}
        >
          <div>
            <ClearIcon
              className="p-2 cursor opacity-80"
              onClick={props.handleClose}
              sx={{ fontSize: 45 }}
              data-cy={"closePostageModal"}
            />
          </div>
          <DialogContent>
            <Typography
              variant="p"
              component="p"
              className="font-size-16 mt-2 mb-3 font-weight-700 opacity-80"
            >
              お客様の配送エリアを選択してください
            </Typography>
            <Grid container>
              <Grid item xs={6} md={2}>
                <Select
                  className="input-y-margin"
                  sx={{ borderRadius: "8px" }}
                  /**if needs multiple select add multiple here */
                  value={area}
                  onChange={handleChange}
                  input={<BootstrapGreySelect />}
                  data-cy="dropdownList"
                >
                  {names.map((name) => (
                    <MenuItem data-cy={name.jpText} key={name.jpText} value={name.jpText}>
                      {name.jpText}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid container mb={5} mt={2} px={1} columnSpacing={{ md: 5, xs: 2 }}>
              {cartItem &&
                cartItem.map((product, index) => {
                  return (
                    <Grid item xs={12} md={6} key={product.id}>
                      <Grid
                        container
                        px={2}
                        className="custom-border"
                        data-cy={`prefecture:${product.prefectures_name}`}
                      >
                        <Grid item xs={7} md={5}>
                          <Typography
                            variant="span"
                            component="span"
                            className="font-size-14 opacity-80"
                            marginRight="4rem"
                            data-cy="prefecture"
                          >
                            {product.prefectures_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} md={7}>
                          <Typography
                            variant="span"
                            component="span"
                            className="font-size-14"
                            data-cy="postage"
                          >
                            {`${product.postage} 円 `}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
      {/* Mobile View */}
      <div className="">
        <Dialog
          fullScreen
          open={props.open}
          onClose={props.handleClickOpen}
          maxWidth={"sm"}
          fullWidth={true}
          sx={{ display: { xs: "block", md: "none" } }}
          data-cy={"spPostageModal"}
        >
          <DialogTitle className="p-0">
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <div style={{ width: "100%", flexGrow: 1 }}>
                  <Stack
                    style={{ width: "100%" }}
                    flexGrow={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <div>
                      <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 3 }}
                        onClick={() => {
                          props.handleClose();
                        }}
                        data-cy={"spClosePostageModal"}
                      >
                        <FiX className="cursor-pointer-custom" />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        flexGrow: 1,
                        marginRight: "4rem",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        className="font-size-16 opacity-80 font-weight-700"
                        variant="p"
                        component="p"
                      >
                        {"送料について"}
                      </Typography>
                    </div>
                  </Stack>
                </div>
              </Toolbar>
            </AppBar>
          </DialogTitle>
          <DialogContent sx={{ background: "rgba(0, 0, 0, 1)", marginTop: "0" }}>
            <Typography
              variant="p"
              component="p"
              className="font-size-16 mt-2 mb-3 font-weight-700 opacity-80"
            >
              お客様の配送エリアを選択してください
            </Typography>
            <Grid container>
              <Grid item xs={6} md={2}>
                <Select
                  className="input-y-margin"
                  /**if needs multiple select add multiple here */
                  value={area}
                  input={<BootstrapGreySelect />}
                  onChange={handleChange}
                  data-cy="dropdownList"
                >
                  {names.map((name) => (
                    <MenuItem data-cy={name.jpText} key={name.jpText} value={name.jpText}>
                      {name.jpText}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid container mb={5} mt={2}>
              {cartItem &&
                cartItem.map((product, index) => {
                  return (
                    <Grid item xs={12} md={6} key={product.id}>
                      <Grid
                        container
                        className="custom-border"
                        data-cy={`prefecture:${product.prefectures_name}`}
                      >
                        <Grid item xs={5} md={5}>
                          <Typography
                            variant="span"
                            component="span"
                            className="font-size-14 opacity-80"
                            data-cy="prefecture"
                          >
                            {product.prefectures_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={7} md={7}>
                          <Typography
                            variant="span"
                            component="span"
                            className="font-size-14"
                            data-cy="postage"
                          >
                            {`${product.postage} 円 `}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

// Used mainly in B7 and B7 Saga, Fav add & remove
export function formatDataForGtm(productData) {
  return {
    value: productData.total_price ?? "",
    items: {
      item_name: productData.item_title ?? "",
      item_id: productData.item_id ?? "",
      price: productData.sales_price ?? "",
      item_category: productData.category_name ?? "", //part_category
      item_category2: productData.parts_name ?? "",
    },
  };
}

// GA4/item_nameからcategory_nameを取得する処理
export const getCategoryName = (parts, subCategoryName) => {
  for (const category of parts) {
    const subCategory = category.parts.find((subCat) => subCat.name === subCategoryName);
    if (subCategory) {
      return category.category_name;
    }
  }
  return null;
};

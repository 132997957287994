import { PUT_ORDER_HISTORY, PUT_ORDER_HISTORY_DETAILS, GET_HISTORY_LIST } from "./constants";

let initialState = {
  orderHistory: {
    order: null,
  },
  orderHistoryDetails: null,
  totalCount: null,
  historyStatus: []
};
const orderHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: action.payload,
        totalCount: action.totalCount,
      };
    case PUT_ORDER_HISTORY_DETAILS:
      return {
        ...state,
        orderHistoryDetails: action.payload,
      };
    case GET_HISTORY_LIST:
      return {
        ...state,
        historyStatus: action.payload,
      };
    default:
      return state;
  }
};

export { orderHistoryReducer };
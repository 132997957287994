export function setCookie(name: string, value: string, exdays: number = 1): void {
  const d = new Date();
  d.setUTCHours(d.getUTCHours() + exdays * 24);
  const expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function getCookie(name: string): string {
  const value = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith(name + "="))
    ?.split("=")[1];

  return value || "";
}

export function deleteCookie(name: string): void {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export const GET_INFO_DZONE_MY_PAGE_SAGA = "view/MyPage/MyPage/GET_INFO_DZONE_MY_PAGE_SAGA";
export const GET_INFO_DZONE_MY_PAGE__REDUCER = "view/MyPage/MyPage/GET_INFO_DZONE_MY_PAGE__REDUCER";
export const INFO_SET_PROFILE_DZONE_MY_PAGE__REDUCER =
  "view/MyPage/MyPage/INFO_SET_PROFILE_DZONE_MY_PAGE__REDUCER";
export const MOBILE_VIEW_INFO_DZONE_MY_PAGE__REDUCER =
  "view/MyPage/MyPage/MOBILE_VIEW_INFO_DZONE_MY_PAGE__REDUCER";
export const UPDATE_INCHARGE_A22 = "view/MyPage/MyPage/UPDATE_INCHARGE_A22";
export const POST_INCHARGE_A22 = "view/MyPage/MyPage/POST_INCHARGE_A22";
export const UPDATE_INCHARGE_A22_REDUCER = "view/MyPage/MyPage/UPDATE_INCHARGE_A22_REDUCER";
export const DELETE_INCHARGE_A22_A12 = "view/MyPage/MyPage/DELETE_INCHARGE_A22_A12";
export const DELETE_INCHARGE_A22_A12_REDUCER = "view/MyPage/MyPage/DELETE_INCHARGE_A22_A12_REDUCER";
export const GET_INFO_DZONE_INCHARGE_LIST_SAGA =
  "view/MyPage/MyPage/GET_INFO_DZONE_INCHARGE_LIST_SAGA";
export const GET_INFO_DZONE_INCHARGE_LIST_REDUCER =
  "view/MyPage/MyPage/GET_INFO_DZONE_INCHARGE_LIST_REDUCER";

export const CHECKOUT_CART_REDUCER = "view/MyPage/MyPage/CHECKOUT_CART_REDUCER";
export const CHECKOUT_CART_SAGA = "view/MyPage/MyPage/CHECKOUT_CART_SAGA";

export const GET_INFO_DZONE_SHIPPING_LIST_SAGA =
  "view/MyPage/MyPage/GET_INFO_DZONE_SHIPPING_LIST_SAGA";
export const GET_INFO_DZONE_SHIPPING_LIST_REDUCER =
  "view/MyPage/MyPage/GET_INFO_DZONE_SHIPPING_LIST_REDUCER";
export const GET_INFO_DZONE_SHIPPING_LIST_REDUCER_RESET =
  "view/MyPage/MyPage/GET_INFO_DZONE_SHIPPING_LIST_REDUCER_RESET";
export const GET_INFO_DZONE_INCHARGE_LIST_REDUCER_RESET =
  "view/MyPage/MyPage/GET_INFO_DZONE_INCHARGE_LIST_REDUCER_RESET";
export const GET_INFO_DZONE_CART_INFO_C1_SAGA = "view/cart/cart/GET_INFO_DZONE_CART_INFO_C1_SAGA";
export const FETCH_CART_INFO_SAGA = "view/cart/cart/FETCH_CART_INFO_SAGA";
export const GET_INFO_DZONE_CART_INFO_C1_REDUCER =
  "view/cart/cart/GET_INFO_DZONE_CART_INFO_C1_REDUCER";
export const GET_INFO_DZONE_CART_INFO_DEL_C1_SAGA =
  "view/cart/cart/GET_INFO_DZONE_CART_INFO_DEL_C1_SAGA";
export const GET_INFO_DZONE_CART_INFO_DEL_C1_REDUCER =
  "view/cart/cart/GET_INFO_DZONE_CART_INFO_DEL_C1_REDUCER";
export const GET_INFO_DZONE_CART_INFO_USER_C2_SAGA =
  "view/cart/cart/GET_INFO_DZONE_CART_INFO_USER_C2_SAGA";
export const GET_INFO_DZONE_CART_INFO_USER_C2_REDUCER =
  "view/cart/cart/GET_INFO_DZONE_CART_INFO_USER_C2_REDUCER";
export const GET_INFO_DZONE_CART_INFO_DEL_C1_RESET_REDUCER =
  "view/cart/cart/GET_INFO_DZONE_CART_INFO_DEL_C1_RESET_REDUCER";

export const C2_GET_DELIVERY_DATE_LIST = "view/cart/cart/C2_GET_DELIVERY_DATE_LIST";
export const C2_GET_DELIVERY_DATE_LIST_REDUCER = "view/cart/cart/C2_GET_DELIVERY_DATE_LIST_REDUCER";
export const C2_DELETE_DELIVERY_DATE_LIST_REDUCER =
  "view/cart/cart/C2_DELETE_DELIVERY_DATE_LIST_REDUCER";
export const UPADTE_IN_CHARGE_IN_C2_ON_CHANGE = "view/cart/cart/UPADTE_IN_CHARGE_IN_C2_ON_CHANGE";

export const GET_ITEM_POSTAGE_SAGA = "views/cart/GET_ITEM_POSTAGE_SAGA";
export const GET_ITEM_POSTAGE_REDUCER = "views/cart/GET_ITEM_POSTAGE_REDUCER";

export const GET_ORDER_API = "views/cart/GET_ORDER_API";
export const PUT_ORDER_API = "views/cart/PUT_ORDER_API";
export const UPDATE_ORDER_API = "views/cart/UPDATE_ORDER_API";
export const DELETE_CART_API = "views/cart/DELETE_CART_API";

import { styled } from "@mui/material/styles";

export const BpIcon = styled("span")(({ theme }) => ({
  borderColor: "white",
  border: "1px solid grey",
  borderRadius: 3,
  width: 18,
  height: 18,

  ".Mui-focusVisible &": {
    outlineOffset: 2,
  },
}));
export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#E55C5C",
  border: "none",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 18,
    height: 18,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#E55C5C",
  },
});

export function handleNumericFieldKeyPress(event) {
  if (event.which < 48 || event.which > 57) {
    event.preventDefault();
  }
}
export function handlemaxlength(e) {
  if (e.target.value.length > 4) {
    e.preventDefault();
  }
}
export function handleNumbericFieldKeyDown(e) {
  (e.keyCode === 69 ||
    e.keyCode === 187 ||
    e.keyCode === 189 ||
    e.keyCode === 190 ||
    (e.keyCode > 31 &&
      (e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105))) &&
    e.preventDefault();
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function displayHypenIfEmpty(value) {
  if (value == null || value == undefined) {
    return "-";
  }
  if (typeof value == "string" && value.length <= 0) {
    return "-";
  }
  return value;
}

export function formatPinCode(pin) {
  let length = pin && pin.length;
  let formatted = length > 0 ? `${pin.slice(0, 3)}-${pin.slice(3, 7)}` : "";
  return formatted;
}

export function trimWhiteSpace(e) {
  if (e.which === 32) {
    e.preventDefault();
  }
}

import { PUT_CONTACT_INQUIRY } from "./constants";
let initialState = {
  contactInquiryRequest: null,
};
const contactInquiryReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case PUT_CONTACT_INQUIRY:
      actionState.contactInquiryRequest = action.response;
      return actionState;
    default:
      return state;
  }
};
export { contactInquiryReducer };

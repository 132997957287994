import _ from "lodash";
import { PUT_CAR_MODEL_LIST_SAGA, PUT_SUGGESTED_PRODUCT_LIST_SAGA } from "./constants";
let initialState = {
  suggestedProducts: [],
};
const suggestedProductReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case PUT_SUGGESTED_PRODUCT_LIST_SAGA:
      let suggestedProducts;
      if (action.payload.offset != 0) {
        return {
          ...state,
          suggestedProducts: [...state.suggestedProducts, ...action.payload.data],
        };
      }
      return {
        ...state,
        suggestedProducts: action.payload.data,
      };
    default:
      return state;
  }
};

export { suggestedProductReducer };

import {
  GET_INFO_DZONE_CART_INFO_C1_REDUCER,
  GET_INFO_DZONE_CART_INFO_DEL_C1_REDUCER,
  GET_INFO_DZONE_CART_INFO_USER_C2_REDUCER,
  GET_INFO_DZONE_CART_INFO_DEL_C1_RESET_REDUCER,
  C2_DELETE_DELIVERY_DATE_LIST_REDUCER,
  C2_GET_DELIVERY_DATE_LIST_REDUCER,
  UPADTE_IN_CHARGE_IN_C2_ON_CHANGE,
  GET_ITEM_POSTAGE_REDUCER,
  PUT_ORDER_API,
} from "./constants";

let initialState = {
  cartInfo: null,
  delFlg: null,
  user: {},
  in_charge: null,
  deliveryList: null,
  orderdetails: null,
  deleteItems: []
};
const cartReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case GET_INFO_DZONE_CART_INFO_C1_REDUCER:
      return {
        ...state,
        cartInfo: action.payload,
      };
    case GET_INFO_DZONE_CART_INFO_DEL_C1_REDUCER:
      return {
        ...state,
        delFlg: action.payload,
      };
    case GET_INFO_DZONE_CART_INFO_DEL_C1_RESET_REDUCER:
      return {
        ...state,
        delFlg: null,
      };
    case C2_GET_DELIVERY_DATE_LIST_REDUCER:
      return {
        ...state,
        deliveryList: action.payload,
      };
    case GET_INFO_DZONE_CART_INFO_USER_C2_REDUCER:
      actionState.user = action.payload.data && action.payload.data.user;
      actionState.in_charge = action.payload.data && action.payload.data.in_charge;
      return actionState;
    case UPADTE_IN_CHARGE_IN_C2_ON_CHANGE:
      return {
        ...state,
        in_charge: action.payload,
      };
    case GET_ITEM_POSTAGE_REDUCER:
      return {
        ...state,
        postageList: action.payload,
      };
    case PUT_ORDER_API:
      return {
        ...state,
        orderdetails: action.payload,
      };
    case C2_DELETE_DELIVERY_DATE_LIST_REDUCER:
      return {
        ...state,
        deleteItems: action.payload,
      };
    default:
      return actionState;
  }
};

export { cartReducer };

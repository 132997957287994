import instance from "../../../utils/AxiosIntance";
import { GET_REQUEST_PARTS_LIST_ACTION, PUT_REQUEST_PARTS_LIST_SAGA } from "./constants";
import _ from "lodash";
import { GET_REQUEST_PARTS_INFO_API } from "../../../utils/apiConstants";
import { toastr } from "react-redux-toastr";
import { put, takeLatest, call } from "redux-saga/effects";

let response;

function requestPartsListAPICall(payload) {
  // isLoading(true);
  return instance
    .get(GET_REQUEST_PARTS_INFO_API)
    .then((response) => {
      //   isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      //   isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const requestPartsList = function* (payload) {
  try {
    response = yield call(requestPartsListAPICall, payload);
    if (_.get(response, "result", false)) {
      yield put({
        type: PUT_REQUEST_PARTS_LIST_SAGA,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("catch --->");
  }
};

function* requestPartsSaga() {
  yield takeLatest(GET_REQUEST_PARTS_LIST_ACTION, requestPartsList);
}

export default requestPartsSaga;

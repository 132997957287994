import React from "react";
import { createTheme } from "@mui/material/styles";
import { green, purple } from "@mui/material/colors";

const Theme = createTheme({
  palette: {
    mode: "dark",
    secondary: {
      main: "#3D3D3D",
    },
    background: {
      default: "#000000",
    },
    typography: {
      fontFamily: "Noto Sans JP",
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },

    inputlabel: {
      fontFamily: `"Noto Sans JP"`,
      fontSize: 14,
    },
  },
  typography: {
    fontFamily: `"Noto Sans JP"`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  inputlabel: {
    fontFamily: `"Noto Sans JP"`,
    fontSize: 14,
  },
});

export default Theme;

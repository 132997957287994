import React from "react";
// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Grid, Typography } from "@mui/material";
import _ from "lodash";

// CSS
import "./styles.css";

export function DialogWhenApiFail({ isOpen, close, submit, msg, button, buttonValue, title }) {
  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={close}
        aria-describedby="alert-dialog-slide-description"
        data-cy="apiFailDialog"
      >
        <DialogContent className="model-background">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="text-center model-Title"
          >
            <div>{title}</div>
          </DialogContentText>
          {!_.isEmpty(msg) && (
            <DialogContentText
              id="alert-dialog-slide-description"
              className="text-center model-content"
            >
              <div>{msg}</div>
            </DialogContentText>
          )}
        </DialogContent>
        {button && (
          <div className="model-footer-border" onClick={submit} data-cy="apiFailDialogSubmitButton">
            <div className="model-button text-center">{`${buttonValue ? buttonValue : "OK"}`}</div>
          </div>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export function DialogPopup({ isOpen, close, submit, title, msg }) {
  return (
    <React.Fragment>
      <Dialog
        data-cy="dialogPopup"
        open={isOpen}
        onClose={close}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent data-cy="title" className="custom-border-bottom">
          {title && (
            <Typography id="alert-dialog-slide-description" className="text-center model-Title">
              <div>{title}</div>
            </Typography>
          )}
          {!_.isEmpty(msg) && (
            <Typography id="alert-dialog-slide-description" className="text-center model-content">
              <div>{msg}</div>
            </Typography>
          )}
        </DialogContent>

        <Grid>
          <div className="text-center button-container  custom-border-bottom" onClick={submit}>
            <button data-cy="okButton" className="dailog-popup-button">
              OK
            </button>
          </div>
        </Grid>
        <Grid>
          <div className="text-center button-container" onClick={close}>
            <button data-cy="cancelButton" className="cancel-popup-btn">
              キャンセル
            </button>
          </div>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}

export function CheckoutPopup({ isOpen, close, submit, title, msg }) {
  return (
    <>
      <Dialog open={isOpen} onClose={close} aria-describedby="alert-dialog-slide-description">
        <DialogContent className="custom-border-bottom">
          {title && (
            <Typography
              variant="subtitle1"
              id="alert-dialog-slide-description"
              className="text-center model-Title"
            >
              {title}
            </Typography>
          )}
          {!_.isEmpty(msg) && (
            <Typography id="alert-dialog-slide-description" className="text-center model-content">
              <div>{msg}</div>
            </Typography>
          )}
        </DialogContent>

        <Grid>
          <div className="text-center button-container" onClick={submit}>
            <button className="dailog-popup-button">OK</button>
          </div>
        </Grid>
      </Dialog>
    </>
  );
}

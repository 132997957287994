import React from "react";
import { Helmet } from "react-helmet";
import ogThumbnail from "../assets/images/ogp.png";

export default function HelmetMeta(props) {
  const title = props.title ?? "";
  const description = props.description ?? "";
  const url = window.location;
  const showOpenGraphTags = props.showOpenGraphTags ?? false;
  const showTwitterTags = props.showTwitterTags ?? false;

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta charset="utf-8" />
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogThumbnail} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />

        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={ogThumbnail} />
        <meta property="twitter:card" content="" />
      </Helmet>
    </div>
  );
}

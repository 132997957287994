import InputBase from "@mui/material/InputBase";
import { styled, useTheme } from "@mui/material/styles";
import {
  Button,
  Pagination,
  FilledInput,
  OutlinedInput,
  Select,
  Link,
  Popover,
  TextField,
  Grid,
} from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

// Input in use at multiple screen of Phase A & B
export const GreyBorderInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "8px",
    backgroundColor: "rgba(31, 31, 31, 1)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    fontSize: "14px",
    height: "18px",
    padding: "12px 16px",
    color: "rgba(255, 255, 255, 1)",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:focus": {
      borderColor: "rgba(229, 92, 92, 0.8)",
      color: "rgba(255, 255, 255, 1)",
    },
    "&::placeholder": {
      fontSize: "14px",
      opacity: "40%",
      color: "rgba(255, 255, 255, 1)",
    },
  },
}));

// Primary button in use
export const OrangeButton = styled(Button)(({ theme }) => ({
  color: "rgba(255,255,255,0.8)",
  backgroundColor: "rgba(224, 45, 45, 1)",
  borderRadius: "8px",
  paddingTop: "12px",
  paddingBottom: "12px",
  fontSize: "16px",
  "&:disabled": {
    backgroundColor: "rgba(51, 51, 51, 0.6)",
    color: "rgba(102, 102, 102, 0.8)",
  },
  "&:hover": {
    background: "rgba(230, 87, 87, 1)",
  },
  [theme.breakpoints.up("md")]: {
    height: "48px",
  },
  [theme.breakpoints.down("md")]: {
    height: "40px",
  },
}));

// Button in use B-7 screen
export const OrangeOutlineButton = styled(Button)(({ theme }) => ({
  color: "rgba(229, 92, 92, 0.8)",
  backgroundColor: "rgba(0,0,0,1)",
  borderRadius: "8px",
  border: "2px solid rgba(229, 92, 92, 1)",
  paddingTop: "12px",
  paddingBottom: "12px",
  fontSize: "16px",
  "&:disabled": {
    backgroundColor: "rgba(0,0,0, 0.6)",
    color: "rgba(102, 102, 102, 0.8)",
  },
  "&:hover": {
    backgroundColor: "rgba(0,0,0,1)",
    border: "2px solid rgba(230, 87, 87, 1)",
    color: "rgba(230, 87, 87, 1)",
  },
  [theme.breakpoints.up("md")]: {
    height: "48px",
  },
  [theme.breakpoints.down("md")]: {
    height: "40px",
  },
}));

// Orange Link button used in Phase A
export const OrangeLinkButton = styled(Button)(({ theme }) => ({
  color: "rgba(229, 92, 92, 1)",
  backgroundColor: "rgba(0,0,0,0)",
  borderRadius: "8px",
  paddingTop: "12px",
  paddingBottom: "12px",
  fontSize: "16px",
  fontWeight: "700",
  "&:disabled": {
    backgroundColor: "rgba(0,0,0, 0)",
    color: "rgba(229, 92, 92, 0.5)",
  },
  "&:hover": {
    backgroundColor: "rgba(0,0,0, 0)",
    color: "rgba(229, 92, 92, 1)",
    textDecoration: "underline",
  },
  [theme.breakpoints.up("md")]: {
    height: "48px",
  },
  [theme.breakpoints.down("md")]: {
    height: "40px",
  },
}));

// Used in Many screen
export const GreyButton = styled(Button)(({ theme }) => ({
  color: "rgba(255, 255, 255, 1)",
  backgroundColor: "rgba(61, 61, 61, 1)",
  borderRadius: "8px",
  paddingTop: "12px",
  paddingBottom: "12px",
  fontSize: "16px",
  "&:disabled": {
    backgroundColor: "rgba(51, 51, 51, 0.6)",
    color: "rgba(102, 102, 102, 0.8)",
  },
  "&:hover": {
    backgroundColor: "rgba(69, 69, 69, 1)",
  },
  [theme.breakpoints.up("md")]: {
    height: "48px",
  },
  [theme.breakpoints.down("md")]: {
    height: "40px",
  },
}));

//D9 Home コーションプレート、　車検証（QRコード）
export const GreyButtonHomeLg = styled(Button)(({ theme }) => ({
  color: "rgba(255, 255, 255, 1)",
  backgroundColor: "rgba(61, 61, 61, 1)",
  borderRadius: "8px",
  height: "93px",
  fontWeight: "700",
  fontSize: "14px",
  "&:disabled": {
    backgroundColor: "rgba(51, 51, 51, 0.6)",
    color: "rgba(102, 102, 102, 0.8)",
  },
  "&:hover": {
    backgroundColor: "rgba(69, 69, 69, 1)",
  },
}));

//D9 Home 車体番号・類別型式、メーカー
export const GreyButtonHomeMd = styled(Button)(({ theme }) => ({
  color: "rgba(255, 255, 255, 1)",
  backgroundColor: "rgba(61, 61, 61, 1)",
  borderRadius: "8px",
  fontWeight: "700",
  fontSize: "16pxm",
  "&:disabled": {
    backgroundColor: "rgba(51, 51, 51, 0.6)",
    color: "rgba(102, 102, 102, 0.8)",
  },
  "&:hover": {
    backgroundColor: "rgba(69, 69, 69, 1)",
  },
  [theme.breakpoints.up("md")]: {
    height: "48px",
  },
  [theme.breakpoints.down("md")]: {
    height: "41px",
    fontSize: "14px",
  },
}));

// used in MyPageHistory(Details)
export const GreyButtonLg = styled(Button)(({ theme }) => ({
  width: "260px",
  color: "rgba(255,255,255,1)",
  backgroundColor: "rgba(61, 61, 61, 1)",
  borderRadius: "0.5rem",
  padding: "0.6rem",
  fontSize: "1rem",
  textAlign: "center",
  minWidth: "13rem",
  fontWeight: "700",
  "&:disabled": {
    backgroundColor: "rgba(61, 61, 61, 1)",
    color: "rgba(255,255,255,0.2)",
  },
  "&:hover": {
    backgroundColor: "rgba(69, 69, 69, 1)",
  },
}));

export const BootstrapOutLinedSelect = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    height: "auto",
    width: "100%",
    padding: "11px 12px",
    fontSize: "1rem !important",
    color: "rgba(255, 255, 255, 0.6)",
    background: "rgba(31, 31, 31, 1)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    borderRadius: "8px",
  },
  "&:focus": {
    border: "2px solid rgba(255, 255, 255, 0.8)",
  },
}));

export const BootstrapGreySelect = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    height: "auto",
    width: "100%",
    padding: "11px 16px",
    fontSize: "1rem",
    color: "rgba(255, 255, 255, 0.8)",
    background: "rgba(61, 61, 61, 1)",
    border: "none",
    borderRadius: "0.5rem",
  },
  "&:focus": {
    border: "2px solid rgba(255, 255, 255, 0.8)",
  },
}));

export const TransperentSelect = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    height: "auto",
    width: "100%",
    padding: "0.75rem",
    fontSize: "14px !important",
    color: "rgba(255, 255, 255, 0.6)",
    background: "rgba(0,0,0,0)",
    border: "none",
    borderRadius: "0.5rem",
  },
  "&:focus": {
    border: "none",
  },
}));

export const formControlLabelStyle = {
  fontSize: "1rem !important",
  margin: "0",
  padding: "0",
  justifyContent: "space-between",
  width: "100%",
};

export const checkBoxStyle = {
  color: "white",
  "&.Mui-checked": {
    color: "#E55C5C",
  },
};

export const radioStyle = {
  color: "white",
  "&.Mui-checked": {
    color: "#E55C5C",
  },
};

export const BpIcon = styled("span")(({ theme }) => ({
  borderColor: "white",
  border: "1px solid grey",
  borderRadius: 3,
  width: 18,
  height: 18,
  backgroundColor: "rgba(0,0,0,0)",
  ".Mui-focusVisible &": {
    outlineOffset: 2,
  },
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "rgba(229, 92, 92, 1)",
  border: "none",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 18,
    height: 18,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#E55C5C",
  },
});

export const GreyOutLineButton = styled(Button)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: "transparent",
  border: "2px solid #FFFFFF", //#ADADAD
  borderRadius: "8px",
  paddingTop: "12px",
  paddingBottom: "12px",
  fontSize: "16px",
  "&:disabled": {
    backgroundColor: "rgba(0,0,0, 0.6)",
    color: "rgba(102, 102, 102, 0.8)",
    border: "2px solid rgba(102, 102, 102, 0.8)",
  },
  "&:hover": {
    color: "rgba(255, 255, 255, 1)",
    border: "2px solid rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(0,0,0,0)",
  },
  [theme.breakpoints.up("md")]: {
    height: "48px",
  },
  [theme.breakpoints.down("md")]: {
    height: "48px",
  },
}));

export const QuestionMarkIconApp = (props) => (
  <QuestionMarkIcon
    onClick={props.clickAction}
    className="cursor"
    sx={{
      background: "rgba(255, 255, 255, 0.3)",
      borderRadius: "20rem",
      color: "rgba(229, 92, 92, 1)",
      padding: "0.1rem",
      width: "20px",
      height: "20px",
      "&:hover": {
        background: "rgba(255, 255, 255, 0.36)", //Orignal color as per figma
        // background: "#5C5C5C", //not working hover action
      },
    }}
  />
);

export const GreyBorderTextFieldWith44Height = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "8px",
    backgroundColor: "rgba(31, 31, 31, 1)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    fontSize: "14px",
    fontWeight: "400",
    height: "20px", // because of induced height by padding : MUI theme
    padding: "12px 16px",
    color: "#ffff",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:focus": {
      borderColor: "rgba(229, 92, 92, 0.8)",
      color: "rgba(255, 255, 255, 1)",
    },
    "&::placeholder": {
      fontSize: "14px",
      opacity: "40%",
    },
  },
}));

export const GrayButton = styled(Button)(({ theme }) => ({
  /**used in phase A signup screens(multiple screens)*/ color: "#666666",
  backgroundColor: "rgba(51, 51, 51, 1)",
  borderRadius: "8px",
  paddingTop: "11px",
  paddingBottom: "11px",
  height: "31px", // because of induced height by padding : MUI theme
  fontSize: "16px",
  "&:disabled": {
    backgroundColor: "rgba(51, 51, 51, 0.6)",
    color: "rgba(102, 102, 102, 0.8)",
  },
  "&:hover": {
    backgroundColor: "rgba(224, 45, 45, 0.7)",
  },
  [theme.breakpoints.up("md")]: {
    height: "48px",
  },
  [theme.breakpoints.down("md")]: {
    height: "40px",
  },
}));

export const CustomPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    backgroundColor: "rgba(61, 61, 61, 1)",
    borderRadius: "3px",
    border: "none",
    fontSize: "18px",
    color: "background: rgba(255, 255, 255, 0.8)",
    width: "40px",
    height: "40px",
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    backgroundColor: "rgba(31, 31, 31, 1)",
    borderRadius: "3px",
    border: "none",
    fontSize: "18px",
    color: "background: rgba(255, 255, 255, 0.8)",
    width: "40px",
    height: "40px",
  },
}));

export const GreyButtonSm = styled(Button)(({ theme }) => ({
  color: "rgba(255, 255, 255, 1)",
  backgroundColor: "rgba(61, 61, 61, 1)",
  borderRadius: "8px",
  paddingTop: "12px",
  paddingBottom: "12px",
  fontSize: "14px",
  "&:disabled": {
    backgroundColor: "rgba(51, 51, 51, 0.6)",
    color: "rgba(102, 102, 102, 0.8)",
  },
  "&:hover": {
    backgroundColor: "rgba(69, 69, 69, 1)",
  },
  [theme.breakpoints.up("md")]: {
    height: "32px",
  },
  [theme.breakpoints.down("md")]: {
    height: "32px",
  },
}));

export const GreyButtonMobile = styled(Button)(() => ({
  color: "rgba(255,255,255,0.8)",
  backgroundColor: "rgba(61, 61, 61, 1)",
  borderRadius: "0.5rem !important",
  paddingTop: "0.6rem !important",
  paddingBottom: "0.6rem !important",
  fontSize: "1rem !important",
  height: "44px",
  "&:disabled": {
    backgroundColor: "rgba(61, 61, 61, 0.7)",
    color: "rgba(255,255,255,0.2)",
  },
  "&:hover": {
    backgroundColor: "rgba(61, 61, 61, 0.8) !important",
  },
}));

export const customSelectStyle = {
  // height: "auto",
  width: "100%",
  fontSize: "1rem",
  color: "rgba(255, 255, 255, 0.6)",
  background: "rgba(31, 31, 31, 1)",
  // border: "1px solid rgba(255, 255, 255, 0.3)",
  borderRadius: "8px",

  "&:focus": {
    border: "2px solid rgba(255, 255, 255, 0.8)",
  },
};
// Phase A select field
export const GreyOutlinedSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-input": {
    height: "18px",
    padding: "11px 16px",
  },
  width: "100%",
  fontSize: "1rem",
  color: "rgba(255, 255, 255, 1)",
  background: "rgba(31, 31, 31, 1)",
  borderRadius: "8px",
  marginTop: "8px",
  "&:focus": {
    borderColor: "rgba(229, 92, 92, 0.8)",
  },

  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid rgba(229, 92, 92, 0.8)",
  },
  "&::placeholder": {
    color: "rgba(255, 255, 255, 0.6)",
  },
}));
export const OrangeLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  "&:hover": {
    color: "rgba(229, 92, 92, 1)",
    textDecoration: "underline",
  },
}));

export const EditButton = styled(Button)(({ theme }) => ({
  background: "#3D3D3D",
  color: "#ffffff",
  padding: "0.5rem 1rem",
  borderRadius: " 6px",
  "&:hover": {
    background: "#454545;",
  },
}));

export const CheckoutCustomButton = styled(Button)(({ theme }) => ({
  background: "#3D3D3D",
  color: "#ffffff",
  width: "140px",
  padding: "0.3rem 0.5rem",
  borderRadius: " 8px",
  "&:hover": {
    background: "#454545;",
  },
  [theme.breakpoints.only("xs")]: {
    width: "210px",
  },
}));

//  D-9 Screen
export const HomeSearchBar = styled(OutlinedInput)(({ theme }) => ({
  background: "rgba(61, 61, 61, 1)",
  borderRadius: "10px",
  "& .MuiOutlinedInput-notchedOutline": {
    top: "0px",
  },
}));
// A-17,A-20, A-21
export const GreyInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "8px",
    backgroundColor: "rgba(31, 31, 31, 1)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    fontSize: "16px",
    height: "18px",
    padding: "12px 16px",
    color: "rgba(255, 255, 255, 1)",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:focus": {
      borderColor: "rgba(229, 92, 92, 0.8)",
      color: "rgba(255, 255, 255, 1)",
    },
    "&::placeholder": {
      fontSize: "14px",
      opacity: "40%",
      color: "rgba(255, 255, 255, 1)",
    },
  },
}));

export const PopoverCustomized = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    [theme.breakpoints.down(770)]: {
      left: "0 !important",
    },
  },
}));
export const ButtonSmGrey = styled(Button)(({ theme }) => ({
  color: "rgba(255, 255, 255, 1)",
  backgroundColor: "rgba(61, 61, 61, 1)",
  borderRadius: "8px",
  padding: "0.4rem 2rem",
  fontSize: "14px",
  height: "32px",
  "&:disabled": {
    backgroundColor: "rgba(51, 51, 51, 0.6)",
    color: "rgba(102, 102, 102, 0.8)",
  },
  "&:hover": {
    backgroundColor: "rgba(69, 69, 69, 1)",
  },
  [theme.breakpoints.up("md")]: {
    height: "32px",
  },
  [theme.breakpoints.down("md")]: {
    height: "32px",
  },
  [theme.breakpoints.down("338")]: {
    height: "auto",
  },
}));

export const CommentBox = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      background: "#1F1F1F",
      zIndex: -1,
      marginTop: "8px",
      border: "1px solid rgba(255, 255, 255, 0.3)",
    },
    "&:hover fieldset": {
      border: "1px solid rgba(230, 92, 92, 0.8)",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid rgba(230, 92, 92, 0.8)",
      color: "rgb(255, 255, 255)",
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "14px",
    color: "rgb(255, 255, 255)",
    height: "100% !important",
    overflow: "auto",

    "&::placeholder": {
      color: "#FFFFFF",
      opacity: "0.4",
      fontSize: "14px",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export const FixedHeader = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  background: "rgb(39, 39, 39)",
  height: "72px",
  [theme.breakpoints.down("351")]: {
    height: "90px",
  },
  [theme.breakpoints.down("292")]: {
    height: "105px",
  },
}));

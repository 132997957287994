import _ from "lodash";
import { PUT_REQUEST_PARTS_LIST_SAGA } from "./constants";
let initialState = {
  requestPartsList: null,
};
const requestPartsReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case PUT_REQUEST_PARTS_LIST_SAGA:
      actionState.requestPartsList = action.payload;
      return { ...actionState };
    default:
      return state;
  }
};

export { requestPartsReducer };

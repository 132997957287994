import { HOME_PAGE_LIST_REDUCER } from "./constants";

let initialState = {
  favorite_parts: [],
  new_exterior_parts: [],
  new_engine_parts: [],
  mission_parts: [],
  travel_parts: [],
  suspension_parts: [],
  electrical_equipment_parts: [],
  interior_parts: [],
  wheels_tires_parts: [],
  cart_parts: [],
};

const homePageReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case HOME_PAGE_LIST_REDUCER:
      return { ...actionState.homePageReducer, ...action.payload };
    default:
      return state;
  }
};

export { homePageReducer };

import {
  CREATE_REQUEST_PART_CONFIRM_SAGA,
  REMOVE_REQUEST_PART_CONFIRM_STATE_SAGA,
} from "./constants";

let initialState = {
  partRequestToggle: false,
};
const requestPartConfirmReducer = (state = initialState, action) => {
  let actionState = state;
  switch (action.type) {
    case CREATE_REQUEST_PART_CONFIRM_SAGA:
      actionState.partRequestToggle = action.payload;
      return { ...actionState };
    case REMOVE_REQUEST_PART_CONFIRM_STATE_SAGA:
      actionState.partRequestToggle = false;
      return { ...actionState };
    default:
      return state;
  }
};

export { requestPartConfirmReducer };

import Grid from "@mui/material/Grid";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const CORPORATE_URL = "https://www.partsone7.com/";

export default function StickyFooter({ showFooterVersion }) {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        background: "rgb(39, 39, 39)",
        height: { md: "68px" },
        marginTop: "5rem",
        ...(showFooterVersion && {
          paddingBottom: { xs: "45px", sm: "0px" },
        }),
      }}
    >
      <Grid
        item
        xs={12}
        md={10}
        className="px-2 px-md-2"
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
          <Stack direction="row">
            <Typography color="white" className="font-size-14 opacity-80">
              PARTS ONE inc.
            </Typography>
          </Stack>
          <Stack direction="row">
            <a
              href={CORPORATE_URL}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              color="white"
              className="font-size-14 opacity-80 link-hover"
            >
              コーポレートサイト{" "}
            </a>
            <span className="footer-web-border ms-2 me-2 font-size-13">|</span>
            <Link
              to="/term"
              underline="none"
              color="white"
              className="font-size-14 opacity-80 link-hover"
            >
              {" "}
              利用規約
            </Link>
            <span className="footer-web-border ms-2 me-2 font-size-13">|</span>
            <Link
              to="/privacy_policy"
              underline="none"
              color="white"
              className="font-size-14 opacity-80 link-hover"
            >
              {" "}
              プライバシーポリシー
            </Link>
            <span className="footer-web-border ms-2 me-2 font-size-13">|</span>
            <Link
              to="/legal_notice"
              underline="none"
              color="white"
              className="font-size-14 opacity-80 link-hover"
            >
              {" "}
              特定商取引法に基づく表記
            </Link>
            <span className="footer-web-border ms-2 me-2 font-size-13">|</span>
            <Link
              to="/secondhand_notice"
              underline="none"
              color="white"
              className="font-size-14 opacity-80 link-hover"
            >
              {" "}
              古物営業法に基づく表記
            </Link>
          </Stack>
        </Stack>
      </Grid>

      <Grid
        item
        xs={12}
        md={10}
        className="px-2 px-md-2"
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <Grid container>
          <Grid item xs={6}>
            <div className="margin-top-24">
              <a
                href={CORPORATE_URL}
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
                color="white"
                className="font-size-14 opacity-80 link-hover margin-top-24"
              >
                コーポレートサイト{" "}
              </a>
            </div>
            <div className="margin-top-24">
              <Link
                to="/privacy_policy"
                underline="none"
                color="white"
                className="font-size-14 opacity-80 link-hover margin-top-24"
              >
                {" "}
                プライバシーポリシー
              </Link>
            </div>

            <div className="margin-top-24">
              <Link
                to="/secondhand_notice"
                underline="none"
                color="white"
                className="font-size-14 opacity-80 link-hover margin-top-24"
              >
                {" "}
                古物営業法に基づく表示
              </Link>
            </div>
            <div style={{ marginBottom: "16px", marginTop: "28px" }}>
              <Link
                to="/"
                underline="none"
                color="white"
                className="font-size-14 opacity-80 link-hover margin-top-24"
              >
                PARTS ONE inc.
              </Link>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="margin-top-24">
              <Link
                to="/term"
                underline="none"
                color="white"
                className="font-size-14 opacity-80 link-hover margin-top-24"
              >
                {" "}
                利用規約
              </Link>
            </div>
            <div className="margin-top-24">
              <Link
                to="/legal_notice"
                underline="none"
                color="white"
                className="font-size-14 opacity-80 link-hover"
              >
                {" "}
                特定商取引法に基づく表示
              </Link>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import { HOME_PAGE_LIST_REDUCER, HOME_PAGE_LIST_ACTION } from "./constants";
import { put, takeLatest, call } from "redux-saga/effects";
import { HOME_PAGE_API, HOME_PAGE_FOR_USER_API } from "../../utils/apiConstants";
import instance from "../../utils/AxiosIntance";
import { toastr } from "react-redux-toastr";
import _ from "lodash";
import { getCookie } from "../../utils/cookieUtils";

function getHomePageListSagaApiCall(payload) {
  const homePageListApi = !!getCookie("access_token") ? HOME_PAGE_FOR_USER_API : HOME_PAGE_API;

  return instance
    .get(homePageListApi, payload.payload)
    .then((response) => {
      return _.get(response, "data", {});
    })
    .catch((error) => {
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const getHomePageListSaga = function* (payload) {
  let response;
  payload.payload.isLoading(true);
  try {
    response = yield call(getHomePageListSagaApiCall, payload.payload);
    if (_.get(response, "result", false)) {
      yield put({
        type: HOME_PAGE_LIST_REDUCER,
        payload: response.data,
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("Error : ", error);
  }
  payload.payload.isLoading(false);
};

function* homePageSaga() {
  yield takeLatest(HOME_PAGE_LIST_ACTION, getHomePageListSaga);
}

export default homePageSaga;

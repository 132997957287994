import React, { useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import "./global.css";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import Checkout from "./views/Checkout/Checkout";
import CartEmpty from "./views/Cart/CartEmpty";
import CartNoLoginEmpty from "./views/Cart/CartNoLoginEmpty";
import { Box, Grid } from "@mui/material";
import CustomLoader from "./components/Loader";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ScrollToTop from "./components/ScrollToTop";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import { getCookie } from "./utils/cookieUtils";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ENVS from "./constants/envs.ts";

import Error from "./views/Error";
const Page404 = React.lazy(() => import("./views/page404/Page404"));
const Page500 = React.lazy(() => import("./views/page500/Page500"));
const Signup = React.lazy(() => import("./views/signup/signup"));
const SignupConfirm = React.lazy(() => import("./views/signup/signupConfirm"));
const SignupComplete = React.lazy(() => import("./views/signup/signupComplete"));
const SignupCode = React.lazy(() => import("./views/signup/singupCode"));
const Login = React.lazy(() => import("./views/Login/Login"));
const MyPageInfo = React.lazy(() => import("./views/Admin/MyPage/MyPage"));
const MyPageNoLogin = React.lazy(() => import("./views/UserProfile/MyPageNoLogin"));
const MyPagePerson = React.lazy(() => import("./views/Admin/MyPage/MyPagePerson"));
const MyPageSpTop = React.lazy(() => import("./views/Admin/MyPage/MyPageSpTop"));
const RegisterMail = React.lazy(() => import("./views/signup/registerEmail"));
const SignupType = React.lazy(() => import("./views/signup/signupType"));
const ResetPassword = React.lazy(() => import("./views/Login/resetPassword"));
const Members = React.lazy(() => import("./views/Admin/MyPage/Member/Member"));
const EmailUpdatePage = React.lazy(() => import("./views/EmailUpdate/EmailUpdatePage"));
const EmailInputCodePage = React.lazy(() => import("./views/EmailUpdate/EmailInputCodePage"));
const EmailUpdateCompletePage = React.lazy(
  () => import("./views/EmailUpdate/EmailUpdateCompletePage")
);
const ForgotPassword = React.lazy(() => import("./views/Login/forgotPassword"));
const Shipping = React.lazy(() => import("./views/Shipping/Shipping"));
const ShippingDetails = React.lazy(() => import("./views/Shipping/ShippingDetails"));
const MyProfile = React.lazy(() => import("./views/UserProfile/myProfile"));
const CodeInput = React.lazy(() => import("./views/Login/codeInput"));
const About = React.lazy(() => import("./views/About/About"));
const CarSelect = React.lazy(() => import("./views/CarSelect"));
const TypeSearch = React.lazy(() => import("./views/TypeSearch"));
const SearchRequest = React.lazy(() => import("./views/Search/searchRequest"));
const PartsNumberSearchResults = React.lazy(
  () => import("./views/Parts/PartsSearch/GenuinePartsNumberSearch/SearchResults")
);
const MakerSelect = React.lazy(() => import("./views/Search/MakerSelect"));
const ModelSelect = React.lazy(() => import("./views/ModelSelect"));
const ProductPreview = React.lazy(() => import("./views/Product/ProductPreview"));
const RequestConfirm = React.lazy(() => import("./views/Request/RequestConfirm"));
const RequestComplete = React.lazy(() => import("./views/Request/RequestComplete"));
const FavoriteProduct = React.lazy(() => import("./views/Favourite/FavoriteProduct"));
const Cart = React.lazy(() => import("./views/Cart/Cart"));
const PartsSelect = React.lazy(() => import("./views/Parts/PartsSelect"));
const RequestParts = React.lazy(() => import("./views/Request/Select"));
const MyPageCard = React.lazy(() => import("./views/UserProfile/MyPageCard"));
const MyPageHistoryDetails = React.lazy(() => import("./views/MyPage/History/Details"));
const MyPageCardDetails = React.lazy(() => import("./views/UserProfile/MyPageCardDetails"));
const PartsSearch = React.lazy(() => import("./views/Parts/PartsSearch"));
const MyPageWithdraw = React.lazy(() => import("./views/MyPage/MyPageWithdraw"));
const MyPageWithdrawError = React.lazy(() => import("./views/MyPage/MyPageWithdrawError"));
const MyPageWithdrawComplete = React.lazy(() => import("./views/MyPage/MyPageWithdrawComplete"));
const CheckoutComplete = React.lazy(
  () => import("./views/Checkout/CheckoutComplete/CheckoutComplete")
);
const CheckoutConfirm = React.lazy(
  () => import("./views/Checkout/CheckoutConfirm/CheckoutConfirm")
);
const SelectShipping = React.lazy(() => import("./views/Checkout/SelectShipping"));
const MyPageHistory = React.lazy(() => import("./views/History/MyPageHistory"));
const Notice = React.lazy(() => import("./views/Notice/Notice"));
const NoticeDetail = React.lazy(() => import("./views/Notice/NoticeDetail"));
const CautionPlateSerach = React.lazy(() => import("./views/CautionPlate/CautionPlateSerach"));
const SearchModify = React.lazy(() => import("./views/CautionPlate/SearchModify"));
const SearchModifypartTwo = React.lazy(() => import("./views/CautionPlate/SearchModifypartTwo"));
const Home = React.lazy(() => import("./views/Home/Home"));
const Term = React.lazy(() => import("./views/Terms/Term"));
const SecondhandNotice = React.lazy(() => import("./views/Terms/SecondhandNotice"));
const PrivacyPolicy = React.lazy(() => import("./views/Terms/PrivacyPolicy"));
const LegalNotice = React.lazy(() => import("./views/Terms/LegalNotice"));
const QrSearch = React.lazy(() => import("./views/CautionPlate/QrSearch"));
const Faq = React.lazy(() => import("./views/FAQ/Faq"));
const ContactCompleted = React.lazy(() => import("./views/FAQ/ContactCompleted"));
const Contact = React.lazy(() => import("./views/FAQ/ContactForm"));

import * as Sentry from "@sentry/react";
import CardIdProvider from "./contexts/CardIdProvider";

Sentry.init({
  dsn: "https://818a30ecae9c48f3b832a8c6b5e1f12e@o1147142.ingest.sentry.io/4505039962570752",
  // normalizeDepth: 10,
  environment: "dev",

  ignoreErrors: [],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: ENVS.IS_PRODUCTION ? 0.2 : 1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
});

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">読み込み中...</div>
  </div>
);

const queryClient = new QueryClient();

function App() {
  const [showFooter, setShowFooter] = useState(true);
  const [showFooterVersion, setShowFooterVersion] = useState(false);
  const [isLoadingSpinner, setisLoadingSpinner] = useState(false);
  const stripePromise = loadStripe(ENVS.STRIPE_KEY);

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "page_view",
  });

  if (ENVS.IS_PRODUCTION) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        userId: getCookie("access_token"),
        host: window.location.host,
      });
    });
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Helmet></Helmet>
        <BrowserRouter>
          <CardIdProvider>
            <CssBaseline />
            <Box
              sx={{
                display: "grid",
                gridTemplateRows: "auto 1fr auto",
                gridTemplateColumns: "100%",
                minHeight: "100vh",
              }}
            >
              <Sentry.ErrorBoundary
                fallback={({ resetError }) => {
                  return <Error resetError={resetError} />;
                }}
              >
                <NavBar />
                <Grid container justifyContent="center" alignItems="center">
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={
                      window.location.pathname == "/search/caution_plate" ||
                      window.location.pathname == "/search/qr"
                        ? ""
                        : "px-2"
                    }
                    sx={{ minHeight: "100%" }}
                  >
                    <React.Suspense fallback={loading}>
                      {isLoadingSpinner && <CustomLoader />}
                      {/* Scroll Web Page To Top */}
                      <ScrollToTop />
                      <Switch>
                        <Route
                          exact
                          path="/500"
                          name="Page 500"
                          render={(props) => (
                            <Page500
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />
                        <Route /**A-4 */
                          exact
                          path="/auth/signup_input"
                          name="Signup"
                          render={(props) => (
                            <Signup
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /**A-5 */
                          exact
                          path="/auth/signup_confirm"
                          name="SignupConfirm"
                          render={(props) => (
                            <SignupConfirm
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /**A-2 */
                          exact
                          path="/auth/signup_code"
                          name="Signup"
                          render={(props) => (
                            <SignupCode
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /** A-6 */
                          exact
                          path="/auth/signup_complete"
                          name="SignupComplete"
                          render={(props) => (
                            <SignupComplete
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        ></Route>
                        <Route /** A-1 */
                          exact
                          path="/auth/signup"
                          name="signupmail"
                          render={(props) => (
                            <RegisterMail
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /**A-3 */
                          exact
                          path="/auth/signup_type"
                          name="Signuptype"
                          render={(props) => (
                            <SignupType
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        ></Route>
                        <Route /**A-7 */
                          exact
                          path="/auth/login"
                          name="login"
                          render={(props) => (
                            <Login
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /** A-10 */
                          exact
                          path="/auth/password_change/:token"
                          name="reset_password"
                          render={(props) => (
                            <ResetPassword
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /**A-19 */
                          exact
                          path="/auth/password_forget"
                          name="password_forget"
                          render={(props) => (
                            <ForgotPassword
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**A-23 */
                          path="/mypage/shipping"
                          name="Admin_My_Page"
                          render={(props) => (
                            <Shipping
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /**A-8 */
                          exact
                          path="/auth/code_input"
                          name="codeInput"
                          render={(props) => (
                            <CodeInput
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**A-20 */
                          path="/mypage/shipping_add/new"
                          name="ShippingDetails"
                          render={(props) => (
                            <ShippingDetails
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /**A-21 */
                          exact
                          path="/mypage/shipping_edit/edit"
                          name="ShippingEdit"
                          render={(props) => (
                            <ShippingDetails
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /**A-17 */
                          exact
                          path="/mypage/profile_edit"
                          name="MyProfile"
                          render={(props) => (
                            <MyProfile
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**A-22 */
                          path="/mypage/member"
                          name="members_My_Page"
                          render={(props) => (
                            <Members
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**A-24 */
                          path="/mypage/change"
                          name="email_change"
                          render={(props) => (
                            <EmailUpdatePage
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**A-25 */
                          path="/mypage/InputCode"
                          name="email_input_code"
                          render={(props) => (
                            <EmailInputCodePage
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**A-26 */
                          path="/mypage/changeComplete"
                          name="email_change_complete"
                          render={(props) => (
                            <EmailUpdateCompletePage
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**A-11 */
                          path="/mypage/no_login"
                          name="mypage_no_login"
                          render={(props) => (
                            <MyPageNoLogin
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /* B-4 */
                          exact
                          path="/search/type"
                          name="TypeSearch"
                          render={(props) => (
                            <TypeSearch
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /* B-9 */
                          exact
                          path="/request"
                          name="SearchRequest"
                          render={(props) => (
                            <SearchRequest
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /* B-1 */
                          exact
                          path="/search/maker"
                          name="MakerSelect"
                          render={(props) => (
                            <MakerSelect
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/members/myPage"
                          name="members_My_Page"
                          render={(props) => (
                            <Members
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />
                        {/* B-2 */}
                        <Route
                          path="/search/car"
                          name="carSelect"
                          render={(props) => (
                            <CarSelect
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        {/* B-3 */}
                        <Route
                          path="/search/model"
                          name="modelSelect"
                          render={(props) => (
                            <ModelSelect
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        {/* B-7 */}
                        <Route
                          path="/product/:id"
                          name="productDetails"
                          render={(props) => (
                            <ProductPreview
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(true)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /* B-11 */
                          path="/request/confirm"
                          name="requestConfirm"
                          render={(props) => (
                            <RequestConfirm
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /* B-13 */
                          path="/favorite"
                          name="favoriteProduct"
                          render={(props) => (
                            <FavoriteProduct
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />

                        <Route /* B-12 */
                          path="/request/complete"
                          name="requestComplete"
                          render={(props) => (
                            <RequestComplete
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/cart" /**C-1 */
                          name="cart"
                          render={(props) => (
                            <Cart
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(true)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/cart/no-login-empty" /**C-1 Cart No-Login-Empty*/
                          name="cart-no-login-empty"
                          render={(props) => (
                            <CartNoLoginEmpty
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/cart/empty" /**C-1 Cart Empty*/
                          name="cart-empty"
                          render={(props) => (
                            <CartEmpty
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          /**C-6 My Page card details */
                          path="/mypage/card/:id"
                          name="MyPageCardDetails"
                          render={(props) => (
                            <MyPageCardDetails
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />

                        <Route
                          /**D-2 My Page card */
                          path="/mypage/history_details"
                          name="MyPageHistoryDetails"
                          render={(props) => (
                            <MyPageHistoryDetails
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />

                        <Route
                          /**C-5 My Page card */
                          path="/mypage/card"
                          name="MyPageCard"
                          render={(props) => (
                            <MyPageCard
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /** D-3 */
                          exact
                          path="/mypage/withdraw"
                          name="mypage_withdraw"
                          render={(props) => (
                            <MyPageWithdraw
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /** D-3 */
                          exact
                          path="/mypage/withdraw/error"
                          name="mypage_withdraw_error"
                          render={(props) => (
                            <MyPageWithdrawError
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />
                        <Route /** D-3 */
                          exact
                          path="/mypage/withdraw/complete"
                          name="mypage_withdraw_complete"
                          render={(props) => (
                            <MyPageWithdrawComplete
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />
                        <Route /** D-8 */
                          exact
                          path="/notice"
                          name="notice"
                          render={(props) => (
                            <Notice
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /** D-8 */
                          exact
                          path="/notice/news"
                          name="notice-news"
                          render={(props) => (
                            <Notice
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /** D-9 */
                          path="/notice/:id"
                          name="notice_detail"
                          render={(props) => (
                            <NoticeDetail
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /** D-16 */
                          exact
                          path="/term"
                          name="term"
                          render={(props) => (
                            <Term
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />
                        <Route /** D-19 */
                          exact
                          path="/secondhand_notice"
                          name="secondhand_notice"
                          render={(props) => (
                            <SecondhandNotice
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />
                        <Route /** D-17 */
                          exact
                          path="/privacy_policy"
                          name="privacy_policy"
                          render={(props) => (
                            <PrivacyPolicy
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />
                        <Route /** D-18 */
                          exact
                          path="/legal_notice"
                          name="legal_notice"
                          render={(props) => (
                            <LegalNotice
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />
                        <Route
                          exact /**A-14 */
                          path="/mypage/about"
                          name="members_My_Page"
                          render={(props) => (
                            <About
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />
                        <Route
                          exact /**A-12 */
                          path="/mypage/person"
                          name="Admin_My_Page_person"
                          render={(props) => (
                            <MyPagePerson
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**A-12 A-13*/
                          path="/mypage/spTop"
                          name="Admin_My_Page_person"
                          render={(props) => (
                            <MyPageSpTop
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />
                        <Route
                          exact /**A-12 */
                          path="/mypage"
                          name="Admin_My_Page"
                          render={(props) => (
                            <MyPageInfo
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**B-5 */
                          path="/searchparts"
                          name="parts_select"
                          render={(props) => (
                            <PartsSelect
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**B-10 */
                          path="/request/parts"
                          name="request_parts"
                          render={(props) => (
                            <RequestParts
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**B-6 / B-8 */
                          path="/list"
                          name="request_search"
                          render={(props) => (
                            <PartsSearch
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**B-6 / B-8 */
                          path="/list/search_parts_no"
                          name="request_search"
                          render={(props) => (
                            <PartsNumberSearchResults
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**B-6 / B-8 */
                          path="/list/empty/no_login"
                          name="request_search"
                          render={(props) => (
                            <PartsSearch
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**B-6 / B-8 */
                          path="/list/empty/login"
                          name="request_search"
                          render={(props) => (
                            <PartsSearch
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**C-3*/
                          path="/checkout/confirm"
                          name="checkout_confirm"
                          render={(props) => (
                            <Elements stripe={stripePromise} options={{ locale: "ja" }}>
                              <CheckoutConfirm
                                {...props}
                                setShowFooter={setShowFooter(true)}
                                setShowFooterVersion={setShowFooterVersion(true)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            </Elements>
                          )}
                        />
                        <Route
                          exact /**C-4*/
                          path="/checkout/complete"
                          name="checkout_complete"
                          render={(props) => (
                            <CheckoutComplete
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**C-4*/
                          path="/checkout/complete/:slug"
                          name="checkout_complete"
                          render={(props) => (
                            <CheckoutComplete
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /**C-2 */
                          path="/checkout"
                          name="checkout"
                          render={(props) => (
                            <Checkout
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(true)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route /**C-2 shipping */
                          path="/select/shipping"
                          name="select_shipping"
                          render={(props) => (
                            <SelectShipping
                              {...props}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**D-1*/
                          path="/mypage/history"
                          name="mypage_history"
                          render={(props) => (
                            <MyPageHistory
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Route
                          exact /**D-1*/
                          path="/mypage/history_empty"
                          name="mypage_history"
                          render={(props) => (
                            <MyPageHistory
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />

                        <Route
                          exact /**?*/
                          path="/faq"
                          name="faq"
                          render={(props) => (
                            <Faq
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />

                        <Route
                          exact /**D-15*/
                          path="/contact/complete"
                          name="contact_completed"
                          render={(props) => (
                            <ContactCompleted
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                            />
                          )}
                        />

                        <Route
                          exact /**D-14*/
                          path="/contact"
                          name="contact"
                          render={(props) => (
                            <Contact
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              setShowFooterVersion={setShowFooterVersion(false)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />

                        <Route
                          exact /**D-10*/
                          path="/search/caution_plate"
                          name="caution_plate_search"
                          render={(props) =>
                            isMobile ? (
                              <CautionPlateSerach
                                {...props}
                                setShowFooter={setShowFooter(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            ) : (
                              <Page404
                                {...props}
                                setShowFooter={setShowFooter(true)}
                                setShowFooterVersion={setShowFooterVersion(false)}
                              />
                            )
                          }
                        />
                        <Route
                          exact /**D-10*/
                          path="/search/caution_plate/read"
                          name="caution_plate_search"
                          render={(props) =>
                            isMobile ? (
                              <CautionPlateSerach {...props} setShowFooter={setShowFooter(false)} />
                            ) : (
                              <Page404
                                {...props}
                                setShowFooter={setShowFooter(true)}
                                setShowFooterVersion={setShowFooterVersion(false)}
                              />
                            )
                          }
                        />
                        <Route
                          exact /**D-11-2*/
                          path="/search/caution_plate/modify"
                          name="search_modify"
                          render={(props) =>
                            isMobile ? (
                              <SearchModifypartTwo
                                {...props}
                                setShowFooterVersion={setShowFooterVersion(false)}
                                isLoading={(flag) => {
                                  setisLoadingSpinner(flag);
                                }}
                              />
                            ) : (
                              <Page404
                                {...props}
                                setShowFooter={setShowFooter(true)}
                                setShowFooterVersion={setShowFooterVersion(false)}
                              />
                            )
                          }
                        />
                        <Route
                          exact /**D-11*/
                          path="/search/modify"
                          name="search_modify"
                          render={(props) =>
                            isMobile ? (
                              <SearchModify
                                {...props}
                                setShowFooterVersion={setShowFooterVersion(false)}
                              />
                            ) : (
                              <Page404
                                {...props}
                                setShowFooter={setShowFooter(true)}
                                setShowFooterVersion={setShowFooterVersion(false)}
                              />
                            )
                          }
                        />
                        <Route
                          exact /**D-12*/
                          path="/search/qr"
                          name="qr_search"
                          render={(props) =>
                            isMobile ? (
                              <QrSearch {...props} setShowFooter={setShowFooter(false)} />
                            ) : (
                              <Page404
                                {...props}
                                setShowFooter={setShowFooter(true)}
                                setShowFooterVersion={setShowFooterVersion(false)}
                              />
                            )
                          }
                        />

                        {/* Default to Home D-9 */}
                        <Route
                          exact
                          name="Default"
                          path="/"
                          render={(props) => (
                            <Home
                              {...props}
                              setShowFooter={setShowFooter(true)}
                              isLoading={(flag) => {
                                setisLoadingSpinner(flag);
                              }}
                            />
                          )}
                        />
                        <Redirect exact from="/" to="/" />
                        <Route component={Page404} />
                      </Switch>
                    </React.Suspense>
                  </Grid>
                </Grid>
              </Sentry.ErrorBoundary>
              {showFooter && <Footer id="footer" showFooterVersion={showFooterVersion} />}
            </Box>
          </CardIdProvider>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;

export const GET_ORDER_HISTORY = "view/history/mypage_history_redux/get_order_history";
export const GET_HISTORY_STATUS = "view/history/mypage_history_redux/get_history_status";
export const GET_HISTORY_LIST = "view/history/mypage_history_redux/get_history_list";
export const PUT_ORDER_HISTORY = "view/history/mypage_history_redux/put_order_history";
export const PUT_ORDER_HISTORY_DETAILS =
  "view/history/mypage_history_redux/put_order_history_details";

export const GET_ORDER_HISTORY_API_PAYLOAD = {
  from_date: null,
  to_date: null,
  status: 9,
  order_id: null,
  order_item_id: null,
  item_id: null,
  limit: 5,
  offset: 0,
};

export const GET_ORDER_HISTORY_DETAILS_API_PAYLOAD = {
  from_date: null,
  to_date: null,
  status: 9,
  order_id: null,
  order_item_id: null,
  item_id: null,
  limit: null,
  offset: null,
};

export const KEYS = [
  { name: "length_check", key: 0 },
  { name: "status_check", key: 1 },
  { name: "data_slicing", key: 2 },
];

export const STATUS = [
  { name: "all", jname: "すべて", key: 99 },
  { name: "preparing_to_ship", jname: "未対応", key: 0 },
  { name: "shipped", jname: "注文確定", key: 1 },
  { name: "transaction_cancellation", jname: "発送済み", key: 2 },
  { name: "transaction_cancellation", jname: '取引キャンセル', key: 9 }
];

import { all } from "redux-saga/effects";
import registerFormSaga from "./views/registration/registerFormSaga";
import signSaga from "./views/signup/signupFormSaga";
import myPageNoLoginSaga from "./views/UserProfile/MyPageNoLogin/myPageNoLoginSaga";
import myPageInfoSaga from "./views/Admin/MyPage/MyPage/myPageSaga";
import shippingSaga from "./views/Shipping/Shipping/ShippingSaga";
import searchSaga from "./views/Search/searchSaga";
import productPreviewSaga from "./views/Product/ProductPreviewSaga";
import SuggestedProductSaga from "./components/SuggestProducts/SuggestedProductSaga";
import partsSaga from "./views/Parts/PartsUtils/PartsSaga";
import favoriteProductSaga from "./views/Favourite/FavouriteProductSaga";
import requestPartsSaga from "./views/Parts/RequestParts/RequestPartsSaga";
import requestPartConfirmSaga from "./views/Request/RequestPartConfirmSaga";
import mypageWithdrawSaga from "./views/MyPage/MypageWithdrawSaga";
import cardSaga from "./views/Card/cardSaga";
import orderHistorySaga from "./views/History/MyPageHistoryRedux/orderHistorySaga";
import contactInquirySaga from "./views/FAQ/Contact/ContactSaga";
import cartSaga from "./views/Cart/Cart/cartSaga";
import cautionPlateSaga from "./views/CautionPlate/CautionPlateRedux/cautionPlateSaga";
import homePageSaga from "./views/Home/HomePageSaga";
import NoticeDetailSaga from "./views/Notice/NoticeDetailSaga";

export default function* rootSaga() {
  yield all([
    registerFormSaga(),
    signSaga(),
    myPageNoLoginSaga(),
    myPageInfoSaga(),
    shippingSaga(),
    searchSaga(),
    productPreviewSaga(),
    SuggestedProductSaga(),
    partsSaga(),
    favoriteProductSaga(),
    requestPartsSaga(),
    requestPartConfirmSaga(),
    cardSaga(),
    mypageWithdrawSaga(),
    orderHistorySaga(),
    contactInquirySaga(),
    cautionPlateSaga(),
    cartSaga(),
    homePageSaga(),
    NoticeDetailSaga(),
  ]);
}

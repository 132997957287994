import instance from "../../utils/AxiosIntance";
import {
  CREATE_REQUEST_PART_CONFIRM_ACTION,
  CREATE_REQUEST_PART_CONFIRM_SAGA,
  REMOVE_REQUEST_PART_CONFIRM_STATE_ACTION,
  REMOVE_REQUEST_PART_CONFIRM_STATE_SAGA,
} from "./constants";
import _ from "lodash";
import { REGISTER_PART_INQUIRIES_API } from "../../utils/apiConstants";
import { put, takeLatest, call } from "redux-saga/effects";
import { EMPTY_BODY_NUMBER_SEARCH } from "../Parts/PartsUtils/constants";
import { toastr } from "react-redux-toastr";

const SUCCESS_STATUS_CODE = 200;
let response;

function createRequestPartAPICall(payload) {
  return instance
    .post(REGISTER_PART_INQUIRIES_API, payload)
    .then((response) => {
      return response?.status;
    })
    .catch((error) => {
      toastr.error("エラーが発生しました", error.message || "");
      return error?.response?.status;
    });
}

const createRequestPart = function* (payload) {
  const requestPayload = payload.payload.data;
  const isLoading = payload.payload.isLoading;
  const useIsSubmitting = payload.payload.useIsSubmitting;
  try {
    response = yield call(createRequestPartAPICall, requestPayload);
    if (response === SUCCESS_STATUS_CODE) {
      yield put({
        type: EMPTY_BODY_NUMBER_SEARCH,
        payload: null,
      });
      yield put({
        type: CREATE_REQUEST_PART_CONFIRM_SAGA,
        payload: true,
      });
    } else {
      useIsSubmitting(false);
      isLoading(false);
    }
  } catch (error) {
    console.error("catch --->", error);
  }
};

const removeRequestPartState = function* () {
  try {
    yield put({
      type: REMOVE_REQUEST_PART_CONFIRM_STATE_SAGA,
      payload: [],
    });
  } catch (error) {
    console.error("catch --->");
  }
};

function* requestPartConfirmSaga() {
  yield takeLatest(CREATE_REQUEST_PART_CONFIRM_ACTION, createRequestPart);
  yield takeLatest(REMOVE_REQUEST_PART_CONFIRM_STATE_ACTION, removeRequestPartState);
}

export default requestPartConfirmSaga;

import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import triangleErrorLogo from "../../assets/images/triangleErrorLogo.svg";
import { Grid, Box, Stack, Typography } from "@mui/material";
import { GreyButton } from "../PhaseBStyles/style";
import { isMobile } from "react-device-detect";
import appLogo from "../../assets/images/appLogo.png";
import { FixedHeader } from "../PhaseBStyles/style";

interface ErrorProps {
  resetError: () => void;
}

const Error = ({ resetError }: ErrorProps) => {
  const history = useHistory();
  const resetErrorRef = useRef<() => void>(resetError);

  useEffect(() => {
    return history.listen(() => {
      if (resetErrorRef.current) {
        resetErrorRef.current();
      }
    });
  }, [history]);

  const handleToHome = () => {
    history.push("/");
  };

  return (
    <>
      <LogoHeader />
      <Grid container justifyContent="center" marginTop="3rem">
        <Grid item xs={12} md={8}>
          <Box display="flex" flexDirection="column" alignItems="center" marginBottom="3rem">
            <Box height="70px" width="70px" marginBottom="3rem">
              <img src={triangleErrorLogo} />
            </Box>
            <Typography fontSize="24px">予期せぬエラーが発生しました</Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginBottom={isMobile ? "8rem" : "15rem"}
          >
            <Typography fontWeight="400" fontSize="16px" lineHeight="2" textAlign="center">
              申し訳ございません。
              <br />
              ホーム画面に戻り、再度お試しください。
              <br />
              何度も発生する場合は、お問い合わせください。
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <GreyButton
              className="large-button"
              variant="contained"
              onClick={handleToHome}
              style={{ padding: "6px 50px" }}
            >
              ホームへ戻る
            </GreyButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const LogoHeader = () => {
  const history = useHistory();

  const HandleAppLogo = () => {
    history.push("/");
  };

  return (
    <FixedHeader container className="fixedHeader">
      <Grid item xs={12} md={10} className="px-2 px-md-2">
        <Stack direction="row" alignItems={"center"}>
          <Box className="logo" onClick={HandleAppLogo}>
            <img
              src={appLogo}
              alt="menuLogo"
              style={{ width: "70px", height: "32px", cursor: "pointer" }}
            />
          </Box>
        </Stack>
      </Grid>
    </FixedHeader>
  );
};

export default Error;

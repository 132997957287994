import instance from "../../../utils/AxiosIntance";
import { put, takeLatest, call } from "redux-saga/effects";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { PUT_CONTACT_INQUIRY, GET_CONTACT_INQUIRY } from "./constants";
import { POST_INQUIRY_REGISTER_API } from "../../../utils/apiConstants";

function contactInquiryRequestAPICall(payload, isLoading) {
  isLoading(true);
  return instance
    .post(POST_INQUIRY_REGISTER_API, payload)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const contactInquiryRequest = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  let history = payload.payload.history;

  try {
    response = yield call(contactInquiryRequestAPICall, payload.payload.payload, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: PUT_CONTACT_INQUIRY,
        response: response,
      });

      history.push("/contact/complete");
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    console.error("catch --->");
  }
};

function* contactInquirySaga() {
  yield takeLatest(GET_CONTACT_INQUIRY, contactInquiryRequest);
}

export default contactInquirySaga;

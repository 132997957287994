import axios from "axios";
import _ from "lodash";
import { getCookie, deleteCookie } from "./cookieUtils";
import ENVS from "../constants/envs";

let instance = axios.create({
  baseURL: ENVS.API_END_POINT,
  headers: {
    "Content-type": "application/json",
    locale: "ja",
  },
});
instance.interceptors.response.use(
  (response) => {
    /**Handel SPECIFIC RESPONSE HERE */
    return response;
  },
  (error) => {
    /** HANDEL FAILED CASE HERE*/
    if (
      _.get(error, "response.status", 0) === 401 &&
      window.location.pathname.indexOf("product") !== 1
    ) {
      clearSession();
    }
    throw error;
  }
);

instance.interceptors.request.use((config) => {
  /** SET HEADERS AUTH HERE */
  const accessToken = getCookie("access_token");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

const clearSession = () => {
  deleteCookie("access_token");
  localStorage.removeItem("state");
  if (window.location.pathname !== "/auth/login") window.location.href = "/auth/login";
};

export default instance;
